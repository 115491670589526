import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import useCompanyContext from 'helpers/UseCompanyContext';
import axios from 'helpers/api_helper';
import dayjs from 'dayjs';
import PlaceholderBar from 'components/atoms/PlaceholderBar';
import EventLogClass from 'classes/eventLog';

type EventLogRowProps = {
  events: EventLogClass[];
};

const EventLog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 2rem;
  padding: 0 2rem 2rem 2rem;
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
`;

const EventLogSummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;
`;

const EventLogSummaryText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-weight: bold;
`;

const EventLogDate = styled.div`
  font-size: smaller;
  color: gray;
  margin-left: 5px;
`;

const EventLogDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-left: 7.5rem;
`;

const EventLineItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
`;

const EventAction = styled.div`
  font-size: small;
  color: gray;
  margin-right: 2rem;
  width: 15%;
  text-transform: capitalize;
`;

const EventChange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: gray;
  width: 50%;
`;

const BeforeChange = styled.div`
  width: 50%;
`;

const AfterChange = styled.div`
  width: 50%;
`;

const EventLogRow = ({ events }: EventLogRowProps): ReactElement => {
  const { companyId } = useCompanyContext();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>();

  const splitWord = word => {
    const words = word?.match(/[A-Za-z][a-z]*/g) || [];
    return words?.join(' ');
  };

  const pastTense = name => {
    if (name[name.length - 1] === 'e') {
      return `${name}d`;
    }
    return `${name}ed`;
  };

  const pluralize = name => {
    return `${name}s`;
  };

  const humanReadableEventLogSummary = () => {
    const fullName = `${firstName} ${lastName}`;
    if (events.length === 1) {
      const event = events[0];
      const eventAction = pastTense(event.eventType);

      let eventArticle = 'the';
      if (event.eventField === 'skill') {
        eventArticle = '';
      }
      if (event.eventField === 'tag') {
        eventArticle = 'a';
      }
      if (event.eventField === 'alias') {
        eventArticle = 'an';
      }

      let eventField;
      if (event.eventField === 'skill') {
        eventField = pluralize(event.eventField);
      } else if (event.eventField === '' || event.eventField === null) {
        // empty is a full role change
        eventField = 'Role';
      } else {
        eventField = event.eventField;
      }

      return `${fullName} ${eventAction} ${eventArticle} ${splitWord(eventField).toLowerCase()}`;
    } else if (events.length > 1) {
      return `${fullName} made ${events.length} changes`;
    }
  };

  const getFieldChangedName = (eventField, change) => {
    if (eventField === 'skill') {
      return change
        .filter(skill => skill.skillName !== null)
        .map(skill => skill.skillName)
        .join(', ');
    }
    if (eventField === 'title') {
      return change[0].emsiTitleName;
    }
    if (eventField.includes('benchmark')) {
      return change[0].name;
    }
    if (Array.isArray(change)) {
      return change.join(', ');
    }
    if (typeof change === 'object') {
      return change[eventField]?.toString();
    }
    return change;
  };

  useEffect(() => {
    (async () => {
      if (events[0].eventBy === 'TT User') {
        setFirstName('Talent Transform');
        setLastName('User');
        return;
      }
      try {
        setLoadingUserInfo(true);
        const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/user/${events[0].eventBy}`;
        const userInfo = await axios.get(url);
        setFirstName(userInfo.data.userInfo.details.first_name);
        setLastName(userInfo.data.userInfo.details.last_name);
      } catch (e: any) {
        if (e.response.status === 404) {
          // User has been deleted
          setFirstName(events[0].eventBy);
          setLastName('(Deleted User)');
        } else {
          setFirstName('Unknown');
          setLastName('User');
        }
      } finally {
        setLoadingUserInfo(false);
      }
    })();
  }, [companyId, events]);

  return (
    <EventLog data-cy="role-view-logs-event-log-row">
      {!loadingUserInfo ? (
        <EventLogSummary title={events[0].eventBy} data-cy="role-view-logs-event-log-row-summary">
          <EventLogSummaryText data-cy="role-view-logs-event-log-row-user-info-section">
            {humanReadableEventLogSummary()}
          </EventLogSummaryText>
          <EventLogDate
            title={dayjs(events[0].eventTime).format('hh:mm:ss A')}
            data-cy="role-view-logs-event-log-row-log-date-text"
          >
            {`on ${dayjs(events[0].eventTime).format('MMM D, YYYY')}`}
          </EventLogDate>
        </EventLogSummary>
      ) : (
        <PlaceholderBar />
      )}
      <EventLogDetails data-cy="role-view-logs-event-log-row-log-details">
        {events.map(
          event =>
            event.eventType !== 'create' &&
            event.eventType !== 'delete' && (
              <EventLineItem key={event.logId}>
                <EventAction data-cy="role-view-logs-event-log-row-event-action-text">
                  {event && `${splitWord(event.eventField)} ${splitWord(pastTense(event.eventType))}`}
                </EventAction>
                <EventChange>
                  {event.beforeChange && (
                    <BeforeChange data-cy="role-view-logs-event-log-row-before-change-text">
                      {`${event.afterChange ? 'From: ' : ''} `}
                      {getFieldChangedName(event.eventField, event.beforeChange)}
                    </BeforeChange>
                  )}
                  {event.afterChange && (
                    <AfterChange data-cy="role-view-logs-event-log-row-after-change-text">
                      {`${event.beforeChange ? 'To: ' : ''} `}
                      {getFieldChangedName(event.eventField, event.afterChange)}
                    </AfterChange>
                  )}
                </EventChange>
              </EventLineItem>
            )
        )}
      </EventLogDetails>
    </EventLog>
  );
};

export default EventLogRow;
