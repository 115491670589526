/** SECTIONS:
1. ENUMS
2. INTERFACES
3. TYPES
*/

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// #region 1. ENUMS

export enum BorderColor {
  Default = 'default',
  Subdued = 'subdued',
  Hovered = 'hovered',
  Disabled = 'disabled',
  Information = 'information',
  InformationSubdued = 'informationSubdued',
  Success = 'success',
  Warning = 'warning',
  WarningSubdued = 'warningSubdued',
  Critical = 'critical',
  CriticalSubdued = 'criticalSubdued',
}

export enum CssFlexDirection {
  Column = 'column',
  ColumnReverse = 'column-reverse',
  Row = 'row',
  RowReverse = 'row-reverse',
  Unset = 'unset',
}

export enum CustomThemeName {
  Original = 'globalStylesOriginal',
  LightCast = 'globalStylesLightCast',
  Mango = 'globalStylesMango',
}

export enum EmsiUIBadgeColorPalette {
  SurfaceInformation = 'surfaceInformation',
  SurfaceNeutral = 'surfaceNeutral',
  SurfaceCritical = 'surfaceCritical',
  SurfaceWarning = 'surfaceWarning',
  SurfaceSuccess = 'surfaceSuccess',
  surfaceDisabled = 'surfaceDisabled',
}

export enum RoleAttributeTooltip {
  RoleName = 'This value that can be utilized in situations where the external job title may be referenced by a different name within your organization. If no difference exists, the Job Title should be used.',
  JobCode = 'The value that uniquely identifies your role in your system and is not expected to change. Examples: 123456, ABC123',
  JobTitle = 'Your existing job title that you wish to be normalized. Lightcast recommends minimizing the use of acronyms as this will aid in the normalizing process.',
  JobFunction = 'A value that distinguishes a larger group that the role belongs to. For example, “Software Developer” belongs to “Technology”.',
  JobFamily = 'A value that names a specific occupational area with a job family. For example, “Compensation” or “Benefits” are functions within the family of “Human Resources”.',
  JobDescription = 'This is the full text that is provided on external job postings or stored internally which describes the general responsibilities and requirements of the role necessary to be able to fulfill it.',
  Tags = 'Any label(s) for purposes of identification or to give more information',
  Aliases = 'This value can be utilized to store multiple versions of a title, such as different job posting options. Terms used as aliases can be used to locate roles via the search.',
  Notes = 'Any additional information or context that wants to be stored with the base role fields.',
}

export enum IconColorFilter {
  Default = 'default',
  Subdued = 'subdued',
  Hovered = 'hovered',
  Disabled = 'disabled',
  Information = 'information',
  Interactive = 'interactive',
  Success = 'success',
  Warning = 'warning',
  Critical = 'critical',
  OnInteractive = 'onInteractive',
  OnPrimary = 'onPrimary',
  OnCritical = 'onCritical',
  OnHeaderBar = 'onHeaderBar',
}

export enum SkillDataCategory {
  BenchmarkIndex = 'Benchmark Index',
  FrequencyInBenchmark = 'Frequency In Benchmark',
  FrequencyInBenchmarkNationwide = 'Frequency Nationwide',
  FrequencyInPostings = 'Frequency in Our Postings',
  FrequencyInProfiles = 'Frequency in Profiles',
  Skill = 'Skill',
  SkillType = 'Skill Type',
  PostingsIndex = 'Our Postings Index',
  ProfilesIndex = 'Our Profiles Index',
}

export enum SkillDDNType {
  defining = 'definingSkills',
  distinguishing = 'distinguishingSkills',
  necessary = 'necessarySkills',
}

export enum SkillSuggestionBy {
  All = 'All',
  JobDescription = 'Job Description',
  Benchmark = 'Benchmark',
  Recommended = 'Recommended',
}

export enum SpacingSizes {
  Base = 'base',
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  None = 'none',
}

export enum UILayers {
  Cover = 100,
  PageElement = 400,
  PageHeader = 500,
  Modal = 700,
  SidePanel = 800,
  SiteMenu = 900,
}

export enum SkillLevel {
  Unspecified = 0,
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3,
}

// #endregion ENUMS

// #region 2. INTERFACES

export interface Alias {
  id: number;
  customerId: string;
  roleId: string;
  name: string;
}

export interface Benchmark {
  companies: BenchmarkCategory[];
  locations: BenchmarkCategory[];
  industries: BenchmarkCategory[];
  benchmarkId?: string;
}

export interface BenchmarkCategory {
  id: string;
  name: string;
}

export interface CopyRoleResponse {
  roleId: string;
}

export interface EmsiCompanyTaxonomy {
  id: string;
  name: string;
  score: number;
}

export interface EmsiTitle {
  id: string;
  name: string;
}

export interface ExtractedSkill {
  id: string;
  name: string;
  confidence: number;
  percentPostings: number;
  surfaceForms: any[];
  isAutomated?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  typeId?: string;
  typeName?: string;
  isDeprecated?: boolean;
}

export interface Mapping {
  socs: Soc[];
  names: string[];
  skills: any[];
}

export interface Role {
  roleId?: string;
  roleName?: string;
  skills?: Skill[];
  emsiTitle?: EmsiTitle;
  matchedTitles?: any[];
  soc?: string;
  socName?: string;
}

export interface RoleDetails {
  description?: string;
  notes?: string;
}

export interface RoleFilters {
  matchStatus: string[];
  skillProfileStatus: string[];
  jobTitle: string[];
  jobCode: string[];
  jobFamily: string[];
  jobFunction: string[];
  emsiTitleId: string[];
  soc: string[];
  specializedOccupationId: string[];
  tags: string[];
  aliases: string[];
  skills: string[];
  temporary: string[];
}

export interface Skill {
  skillId: string;
  skillName: string;
  required?: boolean;
  skillLevel?: SkillLevel;
}

export interface Soc {
  id: string;
  name: string;
}

export interface SuggestedSkill extends Skill {
  isSelected?: boolean;
  isManual?: boolean;
  suggestionConfidence?: number;
  percentPostings?: number;
  frequencyInBenchmark?: number;
  frequencyInProfiles?: number;
  frequencyInPostings?: number;
  benchmarkIndex?: number;
  postingsIndex?: number;
  profilesIndex?: number;
  suggestedBy?: SkillSuggestionBy;
  category?: { id: string; name: string };
  subcategory?: { id: string; name: string };
  type?: { id: string; name: string };
  isSoftware?: boolean;
  growthPercent?: number;
  growthCategory?: 'Growing' | 'Rapidly Growing' | 'Stable' | 'Lagging';
  salaryBySkill?: number;
}

export interface Tag {
  id: number;
  customerId: string;
  roleId: string;
  name: string;
}

export interface TopTitleMatch {
  id: string;
  mapping: Mapping;
  confidence: number;
  normalizedTitle: string;
  topMatch: boolean;
}

// #endregion INTERFACES

// #region 3. TYPES

export type BatchDetails = {
  requestId: string;
  filename: string;
  userId: string;
  status: string;
  message: string;
  total?: number;
  errors?: number;
  successes?: number;
  createdAt: Date;
};

export type BatchRowLog = {
  requestId: string;
  name: string;
  rawData?: JSON;
  processData?: JSON;
  rowNumber: number;
  externalId?: string;
  status?: string;
  message?: string;
  processingCount?: number;
  createdAt: Date;
};

export type BenchmarkSkillMatchPercentage = {
  skillProfileId: string;
  benchmarkMatchPercentage: number;
};

export type GetPostingsCountRequestProps = {
  facet: string;
  facetFilters: string[];
  filter?: any;
  companyPostingsId?: string;
};

export type PostingsCount = {
  name: string;
  uniquePostings: string;
};

export type RoleListSort = {
  sortBy: RoleListSortBy;
  sortDirection: RoleListSortDirection;
};

export type RoleListSortBy = 'roleName' | 'mappingStatus';

export type RoleListSortDirection = 'asc' | 'desc';

export type SkillPostingsPercentage = {
  skillId?: string;
  percentage?: number;
};

export type SkillRoleCounts = {
  totalRolesWithSkills: number;
  totalUniqueSkills: number;
  averageSkillsPerRole: number;
};

export type TopSkill = {
  skillId: string;
  skillName: string;
  categoryId: string;
  categoryName: string;
  count: number;
};

export type CategoryCount = {
  categoryId: number;
  categoryName: string;
  count: number;
};

export type SkillTypeCount = {
  typeId: number;
  typeName: string;
  count: number;
};

export type SkillCount = {
  skillId: string;
  skillName: string;
  count: number;
};

export type GetSkillsStatsResponse = {
  skillRoleCounts: SkillRoleCounts;
  skillBreakdowns: {
    topSkills: Array<TopSkill>;
    topSpecializedSkills: Array<SkillCount>;
    topCommonSkills: Array<SkillCount>;
    topCertifications: Array<SkillCount>;
  };
  skillAggregations: {
    skillTypes: Array<SkillTypeCount>;
    skillCategories: Array<CategoryCount>;
  };
};

export type AppliedFacetFilters = { [key: string]: string }[];

export type IntegrationSkillVendor = {
  skillVendorId: string;
  id: string;
  skillVendorName: string;
  inUse: boolean | null;
};

export type CustomSkill = {
  customSkillId: string;
  skillId: string;
  skillName: string;
  skillSource: string;
};

export type ApiResponseData = {
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type ApiErrorResponse = {
  errors: { type: string; message: string }[];
};

export type SubNotificationType = {
  title: string;
  key: string;
  enabled: boolean;
  enabledDescription: string;
  disabledDescription: string;
  notificationConfig: {
    area: string;
    subArea: string;
    notification: string;
    fallback: boolean;
  };
};

export type NotificationType = {
  title: string;
  key: string;
  subNotifications: SubNotificationType[];
};

export type SubOption = {
  id?: string; // this id if for existing integration settings
  title: string;
  key: string;
  description?: string;
  optionConfiguration?: any;
  enabled?: boolean;
  subOptions?: SubOption[];
};

export type Options = {
  title: string;
  key: string;
  subOption: SubOption[];
  loadingSubOptions?: boolean;
};

export type Vendor = {
  id: VendorId;
  status: string;
  type: string;
  vendorName: string;
  skillLevel: Array<CustomSkillLevel>;
};

export type IntegrationCredentials = {
  clientId: string;
  refreshToken: string;
  secret: string;
  skillVendorId: string;
  tenant: string;
  url: string;
  username: string;
  version: string;
};

export type IntegrationScheduleFields = {
  importExternalJobProfiles: IntegrationScheduleType;
  exportInternalJobProfiles: IntegrationScheduleType;
  importExternalSkills: IntegrationScheduleType;
  exportSkillsWithMappings: boolean;
  matchStatus: string;
  tags: string;
};

export type IntegrationSettingType = 'Mappings' | 'Credentials' | 'Schedules';

export enum GenericOptions {
  NoneSpecified = 'None Specified',
}
export enum JobTitleMappingOption {
  JobTitle = 'jobTitle',
}
export enum JobProfilePrivateTitleMappingOption {
  JobProfilePrivateTitle = 'jobProfilePrivateTitle',
}
export enum JobFamilyMappingOption {
  JobFamily = 'jobFamily',
}
export enum JobCodeMappingOption {
  JobCode = 'jobCode',
}

export enum JobDescriptionMappingOption {
  JobDescription = 'jobDescription',
  JobProfileSummary = 'jobProfileSummary',
  JobDescriptionAndSummary = 'jobDescription + jobProfileSummary',
  JobSummaryAndDescription = 'jobProfileSummary + jobDescription',
}

export type IntegrationMappingOptions =
  | JobCodeMappingOption
  | JobTitleMappingOption
  | JobProfilePrivateTitleMappingOption
  | JobDescriptionMappingOption
  | JobFamilyMappingOption
  | GenericOptions;

// TODO: This should probably live in company datastore, maybe we need a vendor metadata table, where we can store these, and retrieve them in the call where we store
// integration vendor details on the company context
export const WorkdayFieldMap = new Map<string, string>([
  [JobCodeMappingOption.JobCode, 'Job_Code'],
  [JobTitleMappingOption.JobTitle, 'Job_Title'],
  [JobDescriptionMappingOption.JobDescription, 'Job_Description'],
  [JobDescriptionMappingOption.JobProfileSummary, 'Job_Profile_Summary'],
  [JobDescriptionMappingOption.JobDescriptionAndSummary, 'Job_Description + Job_Profile_Summary'],
  [JobDescriptionMappingOption.JobSummaryAndDescription, 'Job_Profile_Summary + Job_Description'],
  [JobFamilyMappingOption.JobFamily, 'Job_Family_ID'],
  [JobProfilePrivateTitleMappingOption.JobProfilePrivateTitle, 'Job_Profile_Private_Title'],
]);

export type IntegrationMappings = {
  jobCode: JobCodeMappingOption;
  roleName: JobTitleMappingOption | JobProfilePrivateTitleMappingOption;
  jobTitle: JobTitleMappingOption | JobProfilePrivateTitleMappingOption;
  description: JobDescriptionMappingOption | GenericOptions.NoneSpecified;
  jobFamily: JobFamilyMappingOption | GenericOptions.NoneSpecified;
  jobFunction: JobProfilePrivateTitleMappingOption.JobProfilePrivateTitle | GenericOptions.NoneSpecified;
  notes: IntegrationMappingOptions;
};

export enum IntegrationConfigurationFieldId {
  RoleImportMapping = 'roleImportMapping',
  RoleExportCriteria = 'roleExportCriteria',
  SkillImportCriteria = 'skillExportCriteria',
}

export type VendorCompanyConfiguration = {
  id?: VendorCompanyConfigurationId;
  vendorCompanyId: VendorCompanyId;
  vendor?: Vendor;
  vendorCompany?: VendorCompany;
  fieldId: IntegrationConfigurationFieldId;
  fieldType: string;
  fieldName: string;
  fieldValue: Array<string>;
  protected: boolean;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type StatusType = 'active' | 'inactive';

export type SetIntegrationBody = {
  companyId: string;
  status: StatusType;
  id?: string;
};

export type VendorCompany = {
  id: VendorCompanyId;
  vendor: Vendor;
  companyId: string;
  status: StatusType;
  vendorCompanyConfigurations?: Array<VendorCompanyConfiguration>;
  vendorId: VendorId;
};

export type ApiVendorWithVendorCompany = {
  vendorId: string;
  vendorName: string;
  vendorCompany: VendorCompany;
};

export type ApiVendorCompanyConfiguration = VendorCompanyConfiguration & Omit<ApiResponseData, 'companyId'>;

export type IntegrationSchedule = {
  id?: string;
  companyId: string;
  vendorId: string;
  job: IntegrationScheduleJobType;
  schedule: IntegrationScheduleType;
};

export type IntegrationScheduleJobType =
  | 'importExternalJobProfiles'
  | 'exportInternalJobProfiles'
  | 'importExternalSkills'
  | 'exportSkillsWithMappings';
export type IntegrationScheduleType = 'daily' | 'weekly' | 'monthly' | 'none';

type WithPrefix<T extends string> = `${T}${string}`;
export type CustomSkillId = WithPrefix<'ecds-cs-'>;
export type VendorId = WithPrefix<'ecds-v-'>;
export type VendorCompanyId = WithPrefix<'ecds-vc-'>;
export type VendorCompanyConfigurationId = WithPrefix<'ecds-vcc-'>;

export type CustomSkillLevel = {
  label: string;
  level: number;
};

// #endregion TYPES
