import { useContext, useEffect, useState } from 'react';
import { SearchContext, SearchContextState, SortDirectionOptions } from 'contexts/SearchContext';
import { GridSortItem } from '@mui/x-data-grid';
import queryString from 'query-string';
import { AppliedFacetFilters } from 'types/types';
import SkillProfileSearchParams from 'classes/SkillProfileSearchParams';
import * as _ from 'lodash';

export type SortColumnOptions = 'roleName' | 'skillCount' | 'requiredSkills' | 'roleId' | 'updatedAt';

const useSkillProfilesSearchContext = (): any => {
  const [state, setState] = useContext(SearchContext);
  const [skillProfilesSearchOptionsString, setSkillProfilesSearchOptionsString] = useState<string>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const getSortColumn = (): SortColumnOptions => {
    return state.skillProfilesSearchOptions.pagingSortingOptions.sortOptions.field as SortColumnOptions;
  };

  const getSortDirection = (): SortDirectionOptions => {
    return state.skillProfilesSearchOptions.pagingSortingOptions.sortOptions.sort as SortDirectionOptions;
  };

  const createQueryString = () => {
    return new SkillProfileSearchParams(state.skillProfilesSearchOptions).searchParamsString;
  };

  const setQueryString = (queryString: string) => {
    setState(
      (prevState: SearchContextState): SearchContextState => ({
        ...prevState,
        skillProfilesSearchOptions: { ...prevState.skillProfilesSearchOptions, pathQueryString: queryString },
      })
    );
  };

  const updateQueryString = () => {
    setQueryString(createQueryString());
  };

  const updateSkillProfileSearchOptionsString = (performUpdate: boolean) => {
    if (performUpdate) {
      setSkillProfilesSearchOptionsString(JSON.stringify(state.skillProfilesSearchOptions));
    }
  };

  const setSearchTermFilter = (keyword: string, updateOptionsString = true) => {
    setState(
      (prevState: SearchContextState): SearchContextState =>
        _.set(prevState, 'skillProfilesSearchOptions.filterOptions.searchTermFilter', keyword)
    );
    updateSkillProfileSearchOptionsString(updateOptionsString);
  };

  const setAppliedFilters = (filters: AppliedFacetFilters, updateOptionsString = true) => {
    setState(
      (prevState: SearchContextState): SearchContextState =>
        _.set(prevState, 'skillProfilesSearchOptions.filterOptions.appliedFilters', filters)
    );
    updateSkillProfileSearchOptionsString(updateOptionsString);
  };

  const getAppliedFiltersArray = () => {
    const filters = JSON.parse(JSON.stringify(state.skillProfilesSearchOptions.filterOptions.appliedFilters));
    return filters;
  };

  const setCurrentPage = (currentPage: number, updateOptionsString = true) => {
    setState(
      (prevState: SearchContextState): SearchContextState =>
        _.set(prevState, 'skillProfilesSearchOptions.pagingSortingOptions.currentPage', currentPage)
    );
    updateSkillProfileSearchOptionsString(updateOptionsString);
  };

  const setRowsPerPage = (rowsPerPage: number, updateOptionsString = true) => {
    setState(
      (prevState: SearchContextState): SearchContextState =>
        _.set(prevState, 'skillProfilesSearchOptions.pagingSortingOptions.rowsPerPage', rowsPerPage)
    );
    updateSkillProfileSearchOptionsString(updateOptionsString);
  };

  const setSortOptions = (gridSortItem: GridSortItem, updateOptionsString = true) => {
    setState(
      (prevState: SearchContextState): SearchContextState =>
        _.set(prevState, 'skillProfilesSearchOptions.pagingSortingOptions.sortOptions', {
          field: gridSortItem.field,
          sort: gridSortItem.sort,
        })
    );
    updateSkillProfileSearchOptionsString(updateOptionsString);
  };

  const setStateFromQSObject = (qsObject: any) => {
    setAppliedFilters(JSON.parse(qsObject.af), false);
    setSearchTermFilter(qsObject?.spf, false);
    setCurrentPage(parseInt(qsObject.p), false);
    setRowsPerPage(parseInt(qsObject.n), false);
    setSortOptions({ field: qsObject.sc, sort: qsObject.sd }, false);
  };

  // Set queryString params first time if we have them
  useEffect(() => {
    if (!isLoaded) {
      const initialQueryStringObj: any = queryString.parse(window.location.search, {
        parseBooleans: true,
        parseNumbers: true,
      });
      if (Object.keys(initialQueryStringObj).length > 0) {
        setStateFromQSObject(initialQueryStringObj);
      }
      updateSkillProfileSearchOptionsString(true);
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    updateQueryString();
  }, [skillProfilesSearchOptionsString]);

  return {
    // Search term
    searchTermFilter: state.skillProfilesSearchOptions.filterOptions.searchTermFilter,
    setSearchTermFilter,
    // Applied filters
    getAppliedFiltersArray,
    setAppliedFilters,
    // Paging and sorting
    currentPage: state.skillProfilesSearchOptions.pagingSortingOptions.currentPage,
    rowsPerPage: state.skillProfilesSearchOptions.pagingSortingOptions.rowsPerPage,
    sortOptions: state.skillProfilesSearchOptions.pagingSortingOptions.sortOptions,
    setCurrentPage,
    setRowsPerPage,
    setSortOptions,
    getSortColumn,
    getSortDirection,
    // Query string
    queryString: state.skillProfilesSearchOptions.pathQueryString,
    isLoaded,
  };
};

export default useSkillProfilesSearchContext;
