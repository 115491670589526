import React, { useState, useEffect, useRef, useCallback, ReactElement } from 'react';
import { ExtractedSkill, IconColorFilter, SpacingSizes } from 'types/types';
import { getSkills, getSkillsDecorator } from 'helpers/draft';
import useLevensteinDetector from 'helpers/useLevensteinDetector';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import useSurfaceForms from 'helpers/useSurfaceForms';
import { EditorState, ContentState } from 'draft-js';
import { filterEditorState } from 'draftjs-filters';
import Toolbar from 'components/molecules/draftToolbar';
import Editor from 'components/molecules/draftEditor';
import styled, { useTheme } from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';

type SkillExtractorProps = {
  Wrapper: any;
  Sidebar?: any;
  content?: string;
  onContentChange?: (content: string) => void;
  extractedSkillsHandler?: (extractedSkills: ExtractedSkill[]) => void;
  isExtractingHandler?: (isExtracting: boolean) => void;
  title?: string;
  sidebarTitle?: string;
  placeholder?: string;
  loading?: boolean;
  showToolbar?: boolean;
  readOnly?: boolean;
  noPadding?: boolean;
  dataCy: string;
  isDisabled?: boolean;
  tooltipText?: string;
  tooltipIconColor?: 'Default' | 'Information' | 'Warning' | 'Critical';
};

const EditorWrapper = styled.div``;

const SidebarWrapper = styled.div`
  transform: translateZ(0px) translateY(0px) !important;
  margin-top: 24px;
`;

const ToolbarWrapper = styled.div`
  display: contents;
  background-color: ${props => props.theme.colors.surface.default};
  padding: 0 1.5rem;
  transform: translateZ(0) translateX(-1.5rem) !important;
  width: 100%;
  z-index: 2;
`;

const EditorContainer = styled.div`
  font-size: ${props => props.theme.customTypography.desktop.body.size};
  background-color: ${props => props.theme.colors.surface.default};
  box-sizing: border-box;
  position: relative;
`;

const PopoverIconContainer = styled.div`
  display: flex;
  div {
    cursor: pointer;
  }
`;

const createEditorState = content =>
  content ? EditorState.createWithContent(ContentState.createFromText(content)) : EditorState.createEmpty();

const SkillsExtractor = ({
  Wrapper,
  Sidebar,
  content,
  onContentChange,
  title,
  sidebarTitle,
  placeholder,
  loading = false,
  showToolbar = true,
  readOnly,
  noPadding,
  dataCy,
  extractedSkillsHandler,
  isExtractingHandler,
  isDisabled = false,
  tooltipText,
  tooltipIconColor,
}: SkillExtractorProps): ReactElement => {
  const editorRef = useRef<HTMLElement>();
  const [editorState, setEditorState] = useState(createEditorState(content));
  const [surfaceForms, rawText, debouncedText, setRawText] = useSurfaceForms('', readOnly);
  const [selectedSkillId, setSelectedSkillId] = useState<string | null>(null);
  const [tooltipIconAnchor, setTooltipIconAnchor] = useState<HTMLElement | null>(null);

  const theme = useTheme();

  useEffect(() => {
    setEditorState(createEditorState(content));
    setRawText(content);
  }, [content]);

  const replaceEditorState = useCallback(
    newEditorState => {
      // handle pastes
      if (newEditorState.getLastChangeType() === 'insert-fragment') {
        newEditorState = filterEditorState(
          {
            blocks: ['header-one', 'header-two', 'unordered-list-item'],
            styles: ['BOLD', 'ITALIC'],
            entities: [],
            maxNesting: 1,
            whitespacedCharacters: ['\n', '\t'],
          },
          newEditorState
        );
      }

      setEditorState(newEditorState);
      setRawText((rawText: string) => {
        const newRawText = newEditorState.getCurrentContent().getPlainText();
        if (newRawText !== rawText) {
          setSelectedSkillId(null);
        }
        return newRawText;
      });
    },
    [setEditorState, setRawText]
  );

  useEffect(() => {
    onContentChange?.(debouncedText);
  }, [debouncedText]);

  const extractedSkills = async () => {
    isExtractingHandler?.(true);
    const skills = await getSkills(surfaceForms);
    extractedSkillsHandler?.(skills);
    isExtractingHandler?.(false);
    return skills;
  };

  useEffect(() => {
    extractedSkills();
  }, [surfaceForms]);

  useEffect(() => editorRef.current?.focus({ preventScroll: true }), []);

  // focus
  // useEffect(() => editorRef.current?.focus(), [rawText]);

  // highlight the fetched skills
  useEffect(() => {
    setEditorState(editorState => {
      return EditorState.set(editorState, {
        decorator: getSkillsDecorator(editorState, surfaceForms, selectedSkillId, setSelectedSkillId),
      });
    });
  }, [surfaceForms, selectedSkillId]);

  useLevensteinDetector(rawText);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTooltipIconAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTooltipIconAnchor(null);
  };

  return (
    <>
      <Wrapper className="skills-extractor">
        <EditorWrapper>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            {title ? <FormPartLabel>{title}</FormPartLabel> : null}
            {tooltipText && (
              <PopoverIconContainer
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                data-cy={`tooltip-icon-${title}`}
              >
                <BaseIcon
                  type={IconType.InformationFill}
                  marginLeft={SpacingSizes.None}
                  colorFilter={IconColorFilter[`${tooltipIconColor}`]}
                />
              </PopoverIconContainer>
            )}
          </div>
          {loading ? (
            <Skeleton width={'100%'} height={128} />
          ) : (
            <EditorContainer className="editor-container">
              {showToolbar && (
                <ToolbarWrapper>
                  <Toolbar editorState={editorState} handleEditorChange={replaceEditorState} />
                </ToolbarWrapper>
              )}
              <Editor
                readOnly={readOnly || isDisabled}
                noPadding={noPadding}
                ref={editorRef}
                editorState={editorState}
                onChange={replaceEditorState}
                placeholder={readOnly ? 'n/a' : placeholder || 'Type or paste your text...'}
                dataCy={dataCy}
                opaque={isDisabled}
              />
            </EditorContainer>
          )}
        </EditorWrapper>
        <div>
          {sidebarTitle ? (
            <div>
              <FormPartLabel>{sidebarTitle}</FormPartLabel>
              <Sidebar skills={extractedSkills} setSelectedSkillId={setSelectedSkillId} />
            </div>
          ) : Sidebar ? (
            <SidebarWrapper>
              <Sidebar skills={extractedSkills} setSelectedSkillId={setSelectedSkillId} />
            </SidebarWrapper>
          ) : null}
        </div>
      </Wrapper>
      <Popover
        anchorEl={tooltipIconAnchor}
        open={!!tooltipIconAnchor}
        onClose={handlePopoverClose}
        sx={{ pointerEvents: 'none' }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          variant={'caption'}
          sx={{ maxWidth: '220px', padding: `${theme.customSpacing.px.xs}px` }}
          component={'div'}
          data-cy={`tooltip-message-${title}`}
        >
          {tooltipText}
        </Typography>
      </Popover>
    </>
  );
};

export default SkillsExtractor;
