import React, { ReactElement, useEffect, useState } from 'react';
import RolesService, { GetRoleUploadStatsResponse } from 'services/RolesService';
import useCompanyContext from 'helpers/UseCompanyContext';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

type RoleUploadFileDetailsProps = {
  fileName: string;
  lastModified: string;
  jobCodes: string[];
  performUpdates: boolean;
  cancelHandler: () => void;
};

const FileDetail = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${props => props.theme.customSpacing.px.xxs}px;
  justify-content: flex-start;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.customSpacing.px.base}px;
  padding-left: ${props => props.theme.customSpacing.px.base}px;
`;

const RoleUploadFileDetails = ({
  fileName,
  lastModified,
  jobCodes,
  performUpdates,
  cancelHandler,
}: RoleUploadFileDetailsProps): ReactElement => {
  const [roleUploadStats, setRoleUploadStats] = useState<GetRoleUploadStatsResponse | undefined>(undefined);
  const [loadingRoleUploadStats, setLoadingRoleUploadStats] = useState(false);

  const { companyId } = useCompanyContext();
  useEffect(() => {
    const getRoleStats = async () => {
      if (!jobCodes) setRoleUploadStats(undefined);
      else {
        setLoadingRoleUploadStats(true);
        const result = await RolesService.getRoleUploadStats({ companyId, jobCodes });
        setRoleUploadStats(result);
        setLoadingRoleUploadStats(false);
      }
    };
    getRoleStats();
  }, [jobCodes]);

  const FileDetailRow = (header: string, text?: string): ReactElement => {
    return (
      <>
        <FileDetail>
          <Typography
            variant="caption"
            color="text.default"
            data-cy={`role-upload-${header.replace(':', '').replace(/\s+/g, '-').toLowerCase()}-header`}
          >
            {header}
          </Typography>
          <Typography
            variant="caption"
            color="text.subdued"
            data-cy={`role-upload-${header.replace(':', '').replace(/\s+/g, '-').toLowerCase()}-value`}
          >
            {text}
          </Typography>
        </FileDetail>
      </>
    );
  };

  return (
    <DetailsContainer>
      <FileDetailsContainer>
        {FileDetailRow('File Name:', `${fileName}`)}
        {FileDetailRow('Last Modified:', lastModified)}
        <Button
          variant="text"
          onClick={() => cancelHandler()}
          size="small"
          data-cy="add-roles-selector-modal-upload-choose-different-file-link-button"
          sx={{ alignSelf: 'flex-start', paddingLeft: '0px' }}
        >
          choose a different file
        </Button>
      </FileDetailsContainer>
      {jobCodes.length > 0 && (
        <FileDetailsContainer>
          {FileDetailRow('Projected impact: ', '')}
          {!loadingRoleUploadStats ? (
            <>
              {FileDetailRow('Added roles:', roleUploadStats?.newRoles.toString())}
              {roleUploadStats &&
                roleUploadStats?.existingRoles > 0 &&
                FileDetailRow(
                  performUpdates ? 'Updated roles:' : 'Existing roles:',
                  roleUploadStats?.existingRoles.toString()
                )}
            </>
          ) : (
            <>
              <Skeleton width={'100%'} height={'1rem'}></Skeleton>
              <Skeleton width={'100%'} height={'1rem'}></Skeleton>
            </>
          )}
        </FileDetailsContainer>
      )}
    </DetailsContainer>
  );
};

export default RoleUploadFileDetails;
