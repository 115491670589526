import React, { ReactNode, ReactElement, useState } from 'react';
import ButtonGroup from 'components/atoms/ButtonGroup';
import SkillTableSelect from 'components/molecules/SkillTableSelect';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SkillLevel, SkillSuggestionBy, SuggestedSkill } from 'types/types';
import { Skill } from 'classes/skillProfile';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import EditSkillProfileContent from 'components/organisms/EditSkillProfileContent';
import {
  SkillProfileContext,
  SkillProfileContextProvider,
  SkillProfileContextState,
} from 'contexts/SkillProfileContext';
import RolesService from 'services/RolesService';
import UseCompanyContext from 'helpers/UseCompanyContext';
import ReactGA from 'react-ga';
import AutocompleteInput from 'components/molecules/AutocompleteInput';
import useGetSkills from 'helpers/useGetSkills';
import NoDataPlaceholder from 'components/atoms/NoDataPlaceholder';
import Role from 'classes/role';
import useAuthContext from 'helpers/UseAuthContext';
import NavigationSwitcher from 'components/molecules/NavigationSwitcher';
import { lightcastSkillLink } from 'helpers/SkillProfilePageHelper';
import TableSelect from 'components/molecules/TableSelect';

const EllipsizeSkillName = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const SkillNameContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SkillProfileEditPage = (): ReactElement => {
  const { roleId: id } = useParams();
  const { state } = useLocation();
  const [roleId, setRoleId] = useState(id);
  const SkillProfileEditPagePropsState = state as { mode?: string };
  const mode = SkillProfileEditPagePropsState?.mode
    ? SkillSuggestionBy[SkillProfileEditPagePropsState?.mode]
    : SkillSuggestionBy.JobDescription;
  const { isAnalystReadOnly } = useAuthContext();
  const { companyId, getCompanySkillLevelOptions } = UseCompanyContext();
  const navigate = useNavigate();
  const { updateSearch, skillsList, isLoading } = useGetSkills();
  const skillLevelOptions = getCompanySkillLevelOptions();

  const theme = useTheme();

  const styledColumnHeader = (header: string) => {
    return <Typography variant="strong">{header}</Typography>;
  };

  const columns = (
    skills: Array<Skill | SuggestedSkill>,
    loading: boolean,
    updateSkill: (skill: Skill) => void,
    removeSkill: (skillId: Skill['skillId']) => void
  ): GridColDef[] => {
    return [
      {
        field: 'skillName',
        renderHeader: () => styledColumnHeader(`Skills${loading ? '' : ` (${skills.length})`}`),
        flex: 1,
        renderCell: param => {
          return !param.row.customSkillId ? (
            <EllipsizeSkillName href={lightcastSkillLink(param.id.toString())} target="_blank">
              {param.value}
            </EllipsizeSkillName>
          ) : (
            <SkillNameContainer>{param.value}</SkillNameContainer>
          );
        },
      },
      {
        field: 'skillLevel',
        renderHeader: () => styledColumnHeader('Skill Level'),
        flex: 1,
        renderCell: param => {
          return (
            <TableSelect
              field={{
                id: param.row.skillId,
                value: param.row.skillLevel?.toString() || SkillLevel.Unspecified.toString(),
              }}
              options={skillLevelOptions}
              key={param.row.skillId}
              updateHandler={e => {
                const skillLevel = parseInt(e.target.value as string);
                updateSkill({ ...param.row, skillLevel });
              }}
              isUpdating={false}
            />
          );
        },
      },
      {
        field: 'required',
        renderHeader: () => styledColumnHeader('Required'),
        flex: 1,
        renderCell: param => {
          return (
            <SkillTableSelect
              skill={{ skillId: param.row.skillId, skillName: param.row.skillName, required: param.row.required }}
              updateHandler={updateSkill}
              deleteHandler={removeSkill}
            />
          );
        },
      },
    ];
  };

  const rows = (skills: Array<Skill | SuggestedSkill>) => {
    return sortBy(skills, skill => {
      return skill.skillName;
    })?.map(skill => {
      return {
        id: skill.skillId,
        ...skill,
      };
    });
  };

  const setNewRoleId = (newRoleId: string) => {
    window.history.replaceState('', '', `/skill-profiles/${newRoleId}/edit`);
    setRoleId(newRoleId);
  };

  const handleSkillProfileSelectionChange = (newRoleId: string) => {
    setNewRoleId(newRoleId);
  };

  const SidePanelContent = (): ReactNode => {
    return (
      <>
        <SkillProfileContext.Consumer>
          {([{ tempRoleSkills, isLoadingSkillProfile }, setState]) => {
            const addSkill = (skill: Skill) => {
              if (tempRoleSkills?.some(s => s.skillId === skill.skillId)) return;
              setState(
                (previousState: SkillProfileContextState): SkillProfileContextState => ({
                  ...previousState,
                  tempRoleSkills: previousState.tempRoleSkills?.concat(skill),
                  suggestedSkills: previousState.suggestedSkills?.map(suggestedSkill => {
                    return {
                      ...suggestedSkill,
                      isSelected: skill.skillId === suggestedSkill.skillId ? true : suggestedSkill.isSelected,
                    };
                  }),
                })
              );
              ReactGA.event({
                category: 'skill profile',
                action: 'addskill-manually',
                label: 'added',
              });
            };
            const updateSkill = (skill: Skill) => {
              setState(
                (previousState: SkillProfileContextState): SkillProfileContextState => ({
                  ...previousState,
                  tempRoleSkills: previousState.tempRoleSkills?.map(tempSuggestedSkill => {
                    return {
                      ...tempSuggestedSkill,
                      required:
                        skill.skillId === tempSuggestedSkill.skillId ? !!skill.required : tempSuggestedSkill.required,
                      skillLevel:
                        skill.skillId === tempSuggestedSkill.skillId ? skill.skillLevel : tempSuggestedSkill.skillLevel,
                    };
                  }),
                })
              );
            };

            const removeSkill = (skillId: Skill['skillId']) => {
              setTimeout(() => {
                setState(
                  (previousState: SkillProfileContextState): SkillProfileContextState => ({
                    ...previousState,
                    suggestedSkills: previousState.suggestedSkills?.map(skill => {
                      return {
                        ...skill,
                        isSelected: skillId === skill.skillId ? false : skill.isSelected,
                      };
                    }),
                    tempRoleSkills: previousState.tempRoleSkills?.filter(skill => skillId !== skill.skillId),
                  })
                );
              });
            };

            return (
              <>
                <Box m={3}>
                  <AutocompleteInput
                    list={skillsList.map(skill => {
                      return { ...skill, id: skill.id, label: skill.name };
                    })}
                    onInputChange={updateSearch}
                    onItemSelect={skill => {
                      if (skill.id && skill.name) {
                        addSkill({ ...skill, skillId: skill.id, skillName: skill.name });
                        updateSearch();
                      }
                    }}
                    isLoading={isLoading}
                    placeholder="Search to add skills"
                  />
                </Box>
                {!isLoadingSkillProfile && (!tempRoleSkills || tempRoleSkills?.length === 0) ? (
                  <NoDataPlaceholder>
                    <Typography>Skills you add will show up here.</Typography>
                  </NoDataPlaceholder>
                ) : (
                  <DataGrid
                    columns={columns(tempRoleSkills || [], !!isLoadingSkillProfile, updateSkill, removeSkill)}
                    rows={rows(tempRoleSkills || [])}
                    loading={isLoadingSkillProfile}
                    autoHeight
                    disableSelectionOnClick
                    className="skillprofile-active-skills-table"
                  />
                )}
              </>
            );
          }}
        </SkillProfileContext.Consumer>
      </>
    );
  };

  return (
    <>
      {roleId && (
        <SkillProfileContextProvider roleId={roleId} skillsBy={mode}>
          <SkillProfileContext.Consumer>
            {([
              { role, roleSkills, tempRoleSkills, isLoadingSkillProfile, isSavingSkillProfile, descriptionOverride },
              setState,
            ]) => {
              const onSave = async () => {
                // DELETE: Remove skills that are removed by user by comparing roleSkills, tempSkills
                let updateSkills = roleSkills?.filter(skill =>
                  tempRoleSkills
                    ?.map(tempSkill => {
                      return tempSkill.skillId;
                    })
                    .includes(skill.skillId)
                );

                // UPDATE: Update the changes by the user for existing skills
                updateSkills = updateSkills?.map(skill => {
                  return {
                    ...skill,
                    required:
                      tempRoleSkills?.find(tempSkill => tempSkill.skillId === skill.skillId)?.required ||
                      skill.required,
                    skillLevel: tempRoleSkills?.find(tempSkill => tempSkill.skillId === skill.skillId)?.skillLevel || 0,
                  };
                });
                // ADD: Map the newly added skills to match with role skill type
                updateSkills = new Array<any>().concat(
                  updateSkills || [],
                  tempRoleSkills?.filter(
                    tempSkill =>
                      !updateSkills
                        ?.map(updateSkill => {
                          return updateSkill.skillId;
                        })
                        .includes(tempSkill.skillId)
                  ) || []
                );
                // OVERRIDE selected data
                const updateDescription = descriptionOverride?.isOverride
                  ? descriptionOverride.description
                  : role?.details?.description;
                try {
                  setState(
                    (previousState: SkillProfileContextState): SkillProfileContextState => ({
                      ...previousState,
                      isSavingSkillProfile: true,
                    })
                  );
                  const [updateRoleSuccess, updateRoleSkillsSuccess] = await Promise.all([
                    RolesService.setRoleSkills(companyId, roleId, updateSkills),
                    RolesService.updateRole(companyId, roleId, {
                      details: {
                        description: updateDescription,
                      },
                    }),
                  ]);

                  if (updateRoleSuccess && updateRoleSkillsSuccess) {
                    setState(
                      (prevState: SkillProfileContextState): SkillProfileContextState => ({
                        ...prevState,
                        previousState: prevState,
                        role: new Role({
                          ...prevState.role?.data,
                          skills: updateSkills,
                          details: {
                            description: updateDescription,
                          },
                        }),
                        roleSkills: updateSkills,
                        tempRoleSkills: updateSkills,
                      })
                    );
                    ReactGA.event({
                      category: 'skill profile',
                      action: 'update',
                      label: 'save success' + (`- ${role?.roleId}` || ''),
                    });
                  }
                } catch (e) {
                  alert('Unable to save. Try again later.');
                  console.error(e);
                } finally {
                  setState(
                    (previousState: SkillProfileContextState): SkillProfileContextState => ({
                      ...previousState,
                      isSavingSkillProfile: false,
                    })
                  );
                }
              };

              const disableSaveSkillProfile =
                isAnalystReadOnly() ||
                (isEqual(roleSkills, tempRoleSkills) &&
                  (!descriptionOverride?.isOverride ||
                    (descriptionOverride?.isOverride &&
                      isEqual(role?.details?.description, descriptionOverride?.description))));
              return (
                <>
                  <MainContentTemplate
                    pageTitle={'Edit Skill Profile'}
                    isFullWidth={true}
                    topChildrenComponent={
                      <NavigationSwitcher
                        roleId={roleId}
                        onSelectionChange={handleSkillProfileSelectionChange}
                        backClickHandler={() => navigate(`/skill-profiles/${roleId}`)}
                        dataCy="navigation-switcher"
                      />
                    }
                    sidePanelContent={SidePanelContent()}
                    sidePanelHasHeaderDivider={false}
                    sidePanelActions={
                      <ButtonGroup
                        buttons={[
                          {
                            text: 'Cancel',
                            onClickEvent: () => navigate(`/skill-profiles/${roleId}`, { replace: true }),
                            dataCy: 'button-cancel-changes',
                          },
                          {
                            text: 'Review',
                            onClickEvent: () => console.log('Review'),
                            visible: false,
                            dataCy: 'button-review-changes',
                          },
                          {
                            text: 'Save',
                            isLoadingButton: true,
                            loadingStatus: isSavingSkillProfile,
                            onClickEvent: onSave,
                            disabled: disableSaveSkillProfile,
                            dataCy: 'button-save-changes',
                          },
                        ]}
                        wrap="nowrap"
                      />
                    }
                    sidePanelTitle="Skill Profile"
                    sidePanelWidth={550}
                  >
                    {isLoadingSkillProfile ? (
                      <ContentHeaderTemplate
                        contentTitle={'Loading . . .'}
                        displayDivider={true}
                        fullWidthDivider={true}
                      />
                    ) : (
                      <EditSkillProfileContent />
                    )}
                  </MainContentTemplate>
                </>
              );
            }}
          </SkillProfileContext.Consumer>
        </SkillProfileContextProvider>
      )}
      {!roleId && (
        <Typography color={theme.colors.text.critical}>
          Your request is invalid. No Role identifier was provided.
        </Typography>
      )}
    </>
  );
};

export default SkillProfileEditPage;
