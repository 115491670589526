// Icon component that can accept onClick action, but not required
import React, { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { BorderColor, IconColorFilter, SpacingSizes } from 'types/types';

export enum IconType {
  Add = 'add',
  ArrowLeft = 'arrow-left',
  ArrowLeftLine = 'arrow-left-line',
  ArrowRightLine = 'arrow-right-line',
  ArrowLeftRight = 'arrow-left-right',
  ArrowLeftRightDarkBg = 'arrow-left-right-dark-bg',
  Auto = 'auto',
  Check = 'check',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  CircleCheck = 'circle-check',
  Close = 'close',
  Copy = 'copy',
  Delete = 'delete',
  DiagonalArrowDown = 'diagonal-arrow-down',
  DiagonalArrowUp = 'diagonal-arrow-up',
  Download = 'download',
  Edit = 'edit',
  Export = 'export',
  Eye = 'eye',
  EyeOff = 'eye-off',
  ErrorWarningFill = 'error-warning-fill',
  ExternalLink = 'external-link',
  Favorite = 'favorite',
  FileListLine = 'file-list-line',
  Filter = 'filter',
  Flag = 'flag',
  FlagFilled = 'flag-filled',
  InformationFill = 'information-fill',
  Location = 'location',
  Lock = 'lock',
  LogoEmsiGraySmall = 'logo-emsi-gray-small',
  LogoLightCastIcon = 'logo-lightcast-icon',
  LogoTT = 'logo-tt',
  LogoTTText = 'logo-tt-text',
  SearchGiantInverted = 'search-giant-inverted',
  Menu = 'menu',
  Refresh = 'refresh',
  RelatedRoles = 'related-roles',
  Search = 'search',
  Upload = 'upload',
  User = 'user',
}

interface BaseIconProps {
  type: IconType;
  size?: string;
  marginTop?: SpacingSizes;
  marginBottom?: SpacingSizes;
  marginLeft?: SpacingSizes;
  marginRight?: SpacingSizes;
  colorFilter?: IconColorFilter;
  borderColor?: BorderColor;
  onIconClick?: (event: any) => void;
  dataCy?: string;
  alt?: string;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
}

const ActionWrapper = styled.div<{
  $isClickable: boolean;
  $marginTop: number;
  $marginRight: number;
  $marginBottom: number;
  $marginLeft: number;
  $borderColor?: BorderColor;
}>`
  display: inline-flex;
  align-items: center;
  cursor: ${props => (props.$isClickable ? 'pointer' : 'default')};

  ${props =>
    props.$borderColor &&
    css`
      margin: ${props.$marginTop}px ${props.$marginRight}px ${props.$marginBottom}px ${props.$marginLeft}px;
      padding: ${props.theme.customSpacing.px.base}px;
      box-shadow: ${props.$isClickable ? props.theme.boxShadow.surface1 : 'none'};
      border: 1px solid ${props.theme.colors.border[`${props.$borderColor}`]};
      border-radius: 4px;
      background-color: ${props.$isClickable ? 'transparent' : props.theme.colors.surface.disabled};

      &.focus-visible {
        box-shadow: ${props.theme.boxShadow.textFieldFocus};
        outline: none;
      }
    `};

  ${props =>
    props.$borderColor === undefined &&
    css`
      outline: none;

      &.focus-visible > img {
        box-shadow: ${props.theme.boxShadow.textFieldFocus};
      }
    `};
`;

const BaseIconContainer = styled.img<{
  $marginTop: number;
  $marginRight: number;
  $marginBottom: number;
  $marginLeft: number;
  $size: string;
  $colorFilter?: IconColorFilter;
  $enableHover: boolean;
  $borderColor?: BorderColor;
  $disabled?: boolean;
}>`
  margin: ${props => `${props.$marginTop}px ${props.$marginRight}px ${props.$marginBottom}px ${props.$marginLeft}px`};
  width: ${props => props.$size};
  height: ${props => props.$size};
  filter: ${props =>
    props.$disabled
      ? props.theme.colors.icon.disabled.filter
      : props.$colorFilter
        ? props.theme.colors.icon[`${props.$colorFilter}`].filter
        : props.theme.colors.icon.default.filter};

  &:focus-visible ${ActionWrapper} {
    box-shadow: ${props => props.theme.boxShadow.textFieldFocus};
    outline: none;
  }

  ${props =>
    props.$enableHover &&
    css`
      &:hover {
        filter: ${props.theme.colors.icon.hovered.filter};
      }
    `};

  ${props =>
    props.$borderColor &&
    css`
      margin: 0px;
    `};
`;

const BaseIcon = ({
  type,
  size,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  colorFilter = IconColorFilter.Default,
  borderColor,
  onIconClick,
  dataCy,
  alt,
  className,
  disabled = false,
  tabIndex,
}: BaseIconProps): ReactElement => {
  const theme = useTheme();
  const setIcon = () => {
    switch (type) {
      case 'add':
        return '/icons/system/add.svg';
      case 'arrow-left':
        return '/icons/system/arrow-left.svg';
      case 'arrow-right-line':
        return '/icons/system/arrow-right-line.svg';
      case 'arrow-left-right':
        return '/icons/system/arrow-left-right.svg';
      case 'arrow-left-right-dark-bg':
        return '/icons/system/arrow-left-right-dark-bg.svg';
      case 'arrow-left-line':
        return '/icons/system/arrow-left-line.svg';
      case 'auto':
        return '/icons/auto.svg';
      case 'check':
        return '/icons/system/check.svg';
      case 'chevron-down':
        return '/icons/system/chevron-down.svg';
      case 'chevron-left':
        return '/icons/system/chevron-left.svg';
      case 'chevron-right':
        return '/icons/system/chevron-right.svg';
      case 'chevron-up':
        return '/icons/system/chevron-up.svg';
      case 'circle-check':
        return '/icons/system/circle-check.svg';
      case 'diagonal-arrow-down':
        return '/icons/system/diagonal-arrow-down.svg';
      case 'diagonal-arrow-up':
        return '/icons/system/diagonal-arrow-up.svg';
      case 'download':
        return '/icons/system/download.svg';
      case 'close':
        return '/icons/system/close.svg';
      case 'copy':
        return '/icons/system/copy.svg';
      case 'delete':
        return '/icons/system/delete.svg';
      case 'edit':
        return '/icons/system/edit.svg';
      case 'error-warning-fill':
        return '/icons/system/error-warning-fill.svg';
      case 'export':
        return '/icons/system/export.svg';
      case 'external-link':
        return '/icons/system/external-link.svg';
      case 'eye':
        return '/icons/system/eye.svg';
      case 'eye-off':
        return '/icons/system/eye-off.svg';
      case 'favorite':
        return '/icons/system/favorite.svg';
      case 'filter':
        return '/icons/system/filter.svg';
      case 'file-list-line':
        return '/icons/system/file-list-line.svg';
      case 'flag':
        return '/icons/system/flag.svg';
      case 'flag-filled':
        return '/icons/system/flag-filled.svg';
      case 'information-fill':
        return '/icons/system/information-fill.svg';
      case 'location':
        return '/icons/system/location.svg';
      case 'lock':
        return '/icons/system/lock.svg';
      case 'logo-emsi-gray-small':
        return '/icons/emsi-logo-gray-small.svg';
      case 'logo-lightcast-icon':
        return '/icons/logo-lightcast-icon.svg';
      case 'logo-tt':
        return '/icons/talent-transform-logo.svg';
      case 'logo-tt-text':
        return '/icons/talent-transform-text.svg';
      case 'menu':
        return '/icons/system/menu.svg';
      case 'refresh':
        return '/icons/system/refresh.svg';
      case 'related-roles':
        return '/icons/system/related-roles.svg';
      case 'search':
        return '/icons/system/search.svg';
      case 'search-giant-inverted':
        return '/icons/system/search-giant-inverted.svg';
      case 'upload':
        return '/icons/upload-icon.svg';
      case 'user':
        return '/icons/system/user.svg';
    }
  };

  return (
    <>
      <ActionWrapper
        tabIndex={tabIndex || 0}
        onClick={e => !disabled && onIconClick?.(e)}
        $isClickable={onIconClick !== undefined && !disabled}
        $marginTop={marginTop ? theme.customSpacing.px[`${marginTop}`] : theme.customSpacing.px.none}
        $marginRight={marginRight ? theme.customSpacing.px[`${marginRight}`] : theme.customSpacing.px.base}
        $marginBottom={marginBottom ? theme.customSpacing.px[`${marginBottom}`] : theme.customSpacing.px.none}
        $marginLeft={marginLeft ? theme.customSpacing.px[`${marginLeft}`] : theme.customSpacing.px.base}
        $borderColor={borderColor}
      >
        <BaseIconContainer
          src={setIcon()}
          $size={size || '16px'}
          $marginTop={marginTop ? theme.customSpacing.px[`${marginTop}`] : theme.customSpacing.px.none}
          $marginRight={marginRight ? theme.customSpacing.px[`${marginRight}`] : theme.customSpacing.px.base}
          $marginBottom={marginBottom ? theme.customSpacing.px[`${marginBottom}`] : theme.customSpacing.px.none}
          $marginLeft={marginLeft ? theme.customSpacing.px[`${marginLeft}`] : theme.customSpacing.px.base}
          $colorFilter={disabled ? IconColorFilter.Disabled : colorFilter}
          $borderColor={borderColor}
          data-cy={dataCy || `icon-${type}`}
          alt={alt || `${type} icon`}
          className={'image ' + className || 'image'}
          $enableHover={onIconClick !== undefined && !disabled}
          $disabled={disabled}
        />
      </ActionWrapper>
    </>
  );
};

export default BaseIcon;
