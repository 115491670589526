import React, { ReactElement } from 'react';
import ProgressProgressBarSegment from 'components/atoms/ProcessingProgressBarSegment';
import { ProcessingState } from 'helpers/RoleProcessingProgressBarHelper';
import styled from 'styled-components';

export type ProcessingBarData = {
  title: string;
  color: string;
  count: number;
  segmentId?: ProcessingState | string;
};

type ProcessingProgressBarProps = {
  key?: string;
  data: ProcessingBarData[];
  width: string;
  total: number;
  onSegmentClick?: (segmentId: ProcessingState | string) => void;
  highlights?: string[];
};

const Wrapper = styled.div<{ $width }>`
  display: flex;
  width: ${props => props.$width};
  min-width: 50px;
  overflow: hidden;
`;

const ProcessingProgressBar = ({
  width,
  total,
  data,
  key,
  onSegmentClick,
  highlights,
}: ProcessingProgressBarProps): ReactElement => {
  const sumUsed = data.reduce((prev, current) => prev + current.count, 0);
  const dynamicTotal = Math.max(sumUsed, total);

  return (
    <>
      {total !== 0 && (
        <Wrapper $width={width}>
          {data.map(d => {
            const hasHighlights = highlights && highlights.length > 0;
            const isDimmed = hasHighlights && d.segmentId ? !highlights.includes(d.segmentId) : false;
            return (
              <ProgressProgressBarSegment
                key={`${key}-${d.segmentId}`}
                total={dynamicTotal}
                count={d.count}
                color={d.color}
                title={d.title}
                segmentId={d.segmentId}
                onClick={onSegmentClick}
                dimmed={isDimmed}
              />
            );
          })}
        </Wrapper>
      )}
    </>
  );
};

export default ProcessingProgressBar;
