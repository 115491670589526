import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useMemo, ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '@mui/material/Button';
import UseCompanyContext from 'helpers/UseCompanyContext';
import RoleUploadFileModal from 'components/organisms/modals/RoleUploadFileModal';
import RoleEditModal from 'components/organisms/modals/RoleEditModal';
import ReactGA from 'react-ga';
import RolesService from 'services/RolesService';
import { bucketizeTimeLong } from 'helpers/analyticsHelper';
import RolesSearchBar from 'components/molecules/RolesSearchBar';
import useAuthContext from 'helpers/UseAuthContext';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import Role from 'classes/role';
import TablePagination from 'components/molecules/TablePagination';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import Box from '@mui/material/Box';
import { RoleEditContextProvider } from 'contexts/RoleEditContext';
import { DataGrid, GridColDef, GridSortModel, GridRowId, GridRowParams } from '@mui/x-data-grid';
import RoleReviewStatusLabel from 'components/molecules/RoleReviewStatusLabel';
import { SpacingSizes, IconColorFilter } from 'types/types';
import dayjs from 'dayjs';
import RoleViewLogModal from 'components/organisms/modals/RoleViewLogModal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import RoleUpdateMappingsModal from 'components/organisms/modals/RoleUpdateMappingsModal';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import DialogModal from 'components/molecules/DialogModal';
import QuickUpdateSkills, { RoleSkillUpdateType } from 'components/organisms/QuickUpdateSkills';
import useRoleSkillsUpdate from 'helpers/useRoleSkillsUpdate';
import * as ExportService from 'services/ExportService';
import { toast } from 'react-toastify';

const DEFAULT_SORT_COLUMN = 'roleName';
const DEFAULT_SORT_DIRECTION = 'asc';

type ExportArea = 'roles' | 'tags and aliases';

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;

  &.pagination-top {
    padding-left: ${props => props.theme.customSpacing.px.s}px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  &.pagination-bottom {
    box-shadow: ${props => props.theme.boxShadow.surface1};
    padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${props => props.theme.customSpacing.px.xs}px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0ms,
    opacity 0ms;

  .MuiDataGrid-row:hover & {
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 0ms;
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const RolesContainer = styled.div`
  font-size: ${props => props.theme.customTypography.desktop.body.size};
  font-weight: ${props => props.theme.customTypography.desktop.body.weight};
  line-height: ${props => props.theme.customTypography.desktop.body.lineHeight};
`;

const NoRolesContainer = styled.div<{ $backgroundImageUrl: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0;
  padding: 0 1.5rem;
  background-color: ${props => props.theme.colors.surface.disabled};
  border-top: 1px solid ${props => props.theme.colors.border.default};
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
  height: 240px;
  width: 100%;
  background-image: ${props => `url(${props.$backgroundImageUrl})`};
  background-position: right 20% top;
  background-repeat: no-repeat;
  background-size: contain;
`;

const NoRolesMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: normal;
  background: ${props =>
    `linear-gradient(90deg, ${props.theme.colors.surface.disabled}, ${props.theme.colors.surface.disabled}, transparent)`};
`;

const MenuItemCustom = styled(MenuItem)`
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  &.Mui-disabled {
    opacity: 1;

    & > div {
      opacity: 0.38;
    }

    & button {
      pointer-events: initial;
    }
  }
`;

const EllipsizeText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LastUpdatedColumnContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px), (max-width: 1024px) {
    flex-direction: column;
    gap: ${props => props.theme.customSpacing.px.base}px;

    ${IconContainer} {
      gap: ${props => props.theme.customSpacing.px.xxs}px;
    }
  }
`;

const HeaderSubnavContainer = styled(Box)`
  margin: 0px ${props => props.theme.customSpacing.px.m}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .complex-input-bar-container {
    max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 110}px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: ${props => props.theme.customSpacing.px.xxs}px;

    .MuiTablePagination-toolbar {
      justify-content: space-between;
      padding: 0px;
    }

    .complex-input-bar-container {
      max-width: revert;
    }
  }
`;
export interface RolesARToolsPageState {
  filters: any;
}

const RolesARToolsPage = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const qs: any = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });
  const { companyId } = UseCompanyContext();
  const [roles, setRoles] = useState<Role[]>([]);
  const [totalRoleCount, setTotalRoleCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(qs.p || 0);
  const [rowsPerPage, setRowsPerPage] = useState(qs.n || 50);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: qs.sc ? qs.sc : DEFAULT_SORT_COLUMN, sort: qs.sd ? qs.sd : DEFAULT_SORT_DIRECTION },
  ]);
  const [isPageChanged, setIsPageChanged] = useState(false);
  const { isAnalystReadOnly } = useAuthContext();
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [isRoleUpdateInProgress, setIsRoleUpdateInProgress] = useState(false);
  const [roleFilter, setRoleFilter] = useState(qs.rf?.toString() || '');
  const [appliedFilters, setAppliedFilters] = useState(JSON.parse(qs.af || '{}') || {});
  const [exporting, setExporting] = useState(false);
  const [selectedRoles, setSelectedRoles] = React.useState<GridRowId[]>([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [viewLogModalIsOpen, setViewLogModalIsOpen] = useState(false);
  const [updateMappingsModalIsOpen, setUpdateMappingsModalIsOpen] = useState(false);
  const [actionRoleId, setActionRoleId] = useState();
  const [totalFacets, setTotalFacets] = useState({});
  const [isRoleCopy, setIsRoleCopy] = useState(false);
  const [menuItemsAnchor, setMenuItemsAnchor] = useState<null | HTMLElement>(null);
  const [exportItemsMenuAnchor, setExportItemsMenuAnchor] = useState<null | HTMLElement>(null);

  const [showRoleDetailsDialog, setShowRoleDetailsDialog] = useState(false);
  const [clickedRoleId, setClickedRoleId] = useState<GridRowId>();
  const [clickedRole, setClickedRole] = useState<Role>();
  const menuOpen = Boolean(menuItemsAnchor);
  const exportItemsMenuOpen = Boolean(exportItemsMenuAnchor);
  const {
    roleSkillsUpdated,
    updateType,
    setAppArea,
    setUpdateType,
    roleSkillsUpdateInProgress,
    setRoleSkillsUpdateInProgress,
    saveManuallyUpdatedSkills,
  } = useRoleSkillsUpdate();

  const logAnalytics = (label: string) => {
    ReactGA.event({
      category: 'roles-list',
      action: 'role-action',
      label,
    });
  };

  const handleRoleDetailsDialogOpen = (params: GridRowParams) => {
    setClickedRoleId(params.id);
    setShowRoleDetailsDialog(true);
  };

  const handleRoleDetailsDialogClose = () => {
    setShowRoleDetailsDialog(false);
    setClickedRoleId(undefined);
    setClickedRole(undefined);
  };

  useEffect(() => {
    setAppArea('Ar Tools Page');
  }, []);

  const MenuItemWithIcon = (
    menuItemName: string,
    isDisabled: boolean,
    onClick: () => void,
    dataCy: string,
    tooltipTitle: string,
    iconType: IconType,
    iconColor?: IconColorFilter
  ): ReactElement => {
    return (
      <MenuItemCustom data-cy={dataCy} disabled={isDisabled}>
        <>
          <div onClick={onClick}>{menuItemName}</div>
          <Tooltip title={tooltipTitle} placement="right-end" disableFocusListener disableTouchListener>
            <Button variant="text" size="small">
              <BaseIcon
                colorFilter={iconColor || IconColorFilter.Default}
                type={iconType}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.None}
              />
            </Button>
          </Tooltip>
        </>
      </MenuItemCustom>
    );
  };

  const compareRoles = () => {
    ReactGA.event({
      category: 'roles',
      action: 'compare',
      label: 'compare clicked',
    });
    const comparisonRoles = roles.filter(r => selectedRoles.includes(r.roleId));
    navigate(`/roles/compare?roleIds=${comparisonRoles.map(r => r.roleId).join(',')}`);
  };

  const triggerFileExport = async (exportArea: ExportArea, roleIdsToExport?: string[]) => {
    setExporting(true);
    const eventLabel = roleIdsToExport ? 'export selected clicked' : 'export clicked';
    ReactGA.event({
      category: exportArea,
      action: 'export',
      label: eventLabel,
    });

    try {
      const startTime = performance.now();

      let exportTriggerSuccess = false;
      if (exportArea === 'roles') {
        exportTriggerSuccess = await ExportService.triggerExportFile(companyId, 'roles', roleIdsToExport);
      } else if (exportArea === 'tags and aliases') {
        exportTriggerSuccess = await ExportService.triggerExportFile(companyId, 'tagsAliases', roleIdsToExport);
      } else {
        throw new Error('Invalid export area');
      }

      const endTime = performance.now();

      ReactGA.event({
        category: exportArea,
        action: 'export',
        label: `load time: ${bucketizeTimeLong(endTime - startTime)}`,
      });

      exportTriggeredToast(exportTriggerSuccess);
    } catch (e) {
      exportTriggeredToast(false);
      ReactGA.event({
        category: exportArea,
        action: 'export',
        label: 'export failed',
      });
      setExporting(false);
    }
  }

  const exportTriggeredToast = (success: boolean) => {
    const message = success ?
      `Your file is exporting. You will receive an email with a link to the file when the export is complete.` :
      `There was an error exporting your file. Please try again.`;
    const toastContainer = <div><span data-cy="file-export-triggered-toast">{message}</span></div>;

    if (success) {
      toast.success(toastContainer)
    } else {
      toast.error(toastContainer);
    }
  }

  useEffect(() => {
    const loadFacetData = async () => {
      const dbFacets = await RolesService.getRoleFacets(companyId);
      setTotalFacets(dbFacets);
    };
    companyId && roleSkillsUpdated && loadFacetData();
  }, [companyId, roleSkillsUpdated]);

  const hasFilters = useMemo(() => {
    let anyFiltersSet = false;
    Object.keys(appliedFilters).forEach(filter => {
      if (appliedFilters[filter].length > 0) {
        anyFiltersSet = true;
      }
    });
    return anyFiltersSet || (roleFilter && roleFilter.length > 0);
  }, [appliedFilters, roleFilter]);

  const appliedFiltersForQuery = () => {
    const filters = { ...appliedFilters };
    Object.keys(filters).forEach(k => {
      filters[k] = filters[k].map(i => i.value);
    });
    return filters;
  };

  const loadRoles = async () => {
    const filters = appliedFiltersForQuery();
    const { roles, totalCount } = await RolesService.getRoles({
      companyId,
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
      sortColumn: sortModel[0]?.field,
      sortDirection: sortModel[0]?.sort ? (sortModel[0].sort as string) : undefined,
      filters,
      searchTerms: roleFilter,
    });
    setRoles(roles);
    setTotalRoleCount(totalCount);
  };

  const stringifiedAppliedFilters = useMemo(() => {
    return JSON.stringify(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    const getRoles = async () => {
      setLoadingRoles(true);
      await loadRoles();
      setLoadingRoles(false);
    };
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, rowsPerPage, isPageChanged, roleFilter, stringifiedAppliedFilters, sortModel]);

  const augmentedRoles = useMemo(() => {
    if (!roles) {
      return [];
    }

    const lowerFilter = roleFilter.trim().toLowerCase();
    return roles.map(r => {
      r.showReason = 'none';

      // loop over the filters and find roles with names containing at least one of the terms
      if (lowerFilter.length > 0) {
        if (r.roleName.toLowerCase().includes(lowerFilter)) {
          r.showReason = 'title';
        }

        // does an alias match
        r.aliases.forEach(a => {
          if (a.name.toLowerCase().includes(lowerFilter)) {
            r.showReason = 'alias';
          }
        });

        // does the job code match
        if (r.jobCode.toLowerCase() === lowerFilter) {
          r.showReason = 'code';
        }
      }
      return r;
    });
  }, [roles, roleFilter]);

  const handleRoleFilterChange = (newRoleFilter: string) => {
    setRoleFilter((prevRoleFilter: string) => {
      if (newRoleFilter !== prevRoleFilter) {
        setCurrentPage(0);
      }
      return newRoleFilter;
    });
  };

  const handleToggle = (area, value, displayValue) => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (!appliedFiltersCopy[area]) {
      appliedFiltersCopy[area] = [
        {
          key: displayValue || value,
          value,
        },
      ];
    } else {
      const i = appliedFiltersCopy[area].findIndex(f => f.value === value);
      if (i >= 0) {
        appliedFiltersCopy[area].splice(i, 1);
      } else {
        appliedFiltersCopy[area].push({
          key: displayValue || value,
          value,
        });
      }
    }

    // remove empty collections
    if (appliedFiltersCopy[area].length === 0) {
      delete appliedFiltersCopy[area];
    }
    setCurrentPage(0);
    setAppliedFilters(appliedFiltersCopy);
  };

  const selectRoleHandler = (selectedRowIds: GridRowId[]) => {
    const selectedList = Array.from(new Set(selectedRowIds)) as string[];
    setSelectedRoles(selectedList);
  };

  const handleRowClick = (params: GridRowParams) => {
    handleRoleDetailsDialogClose();
    handleRoleDetailsDialogOpen(params);
  };

  const openEditModal = roleId => {
    setActionRoleId(roleId);
    setEditModalIsOpen(true);
  };

  const closeEditModal = async (reload = false) => {
    setIsRoleCopy(false);
    setEditModalIsOpen(false);
    setActionRoleId(undefined);
    reload && (await loadRoles());
  };

  const openViewLogModal = roleId => {
    setActionRoleId(roleId);
    setViewLogModalIsOpen(true);
    ReactGA.event({
      category: 'roles',
      action: 'event logs',
      label: 'show',
    });
  };

  const closeViewLogModal = () => {
    setViewLogModalIsOpen(false);
    setActionRoleId(undefined);
  };

  const closeUpdateMappingsModal = async (reload = false) => {
    setUpdateMappingsModalIsOpen(false);
    if (reload) {
      setIsRoleUpdateInProgress(true);
      await loadRoles();
      setIsRoleUpdateInProgress(false);
    }
  };

  const handleMenuClose = () => {
    setMenuItemsAnchor(null);
    setExportItemsMenuAnchor(null);
  };

  const menuItemGetUserConfirmation = (logAnalyticsLabel: string, confirmationMessage: string): boolean => {
    logAnalytics(logAnalyticsLabel);
    const confirmation = window.confirm(confirmationMessage);
    handleMenuClose();
    return confirmation;
  };

  const reloadRolesAfterBulkChanges = async (results: boolean[], action: 'updated' | 'deleted'): Promise<void> => {
    await loadRoles();
    setIsRoleUpdateInProgress(false);
    const failureCount = results.filter(i => i === false).length;
    if (failureCount > 0) {
      window.alert(`${failureCount} of your ${results.length} roles could not be ${action}. Please try again.`);
    }
  };

  const handleRoleUpdate = async (roleId, updateData) => {
    const success = await RolesService.updateRole(companyId, roleId, updateData);
    return success;
  };

  const handleRoleDelete = async roleId => {
    const success = await RolesService.deleteRole({ customerId: companyId, roleId });
    return success;
  };

  const handleRequestApprovalClick = async () => {
    const isConfirmed = menuItemGetUserConfirmation(
      'request approval selected',
      'Are you sure you would like to request approval for the selected role(s)?'
    );
    if (!isConfirmed) return;

    const promises: Promise<boolean>[] = [];

    selectedRoles.forEach(roleId => {
      promises.push(handleRoleUpdate(roleId, { needsApproval: true }));
    });

    setIsRoleUpdateInProgress(true);
    const results = await Promise.all(promises);
    results.forEach(success => {
      logAnalytics(success ? 'request approval successful' : 'request approval failed');
    });

    await reloadRolesAfterBulkChanges(results, 'updated');
  };

  const handleRoleStatusUpdates = async (update: 'reviewed' | 'unreviewed') => {
    const isConfirmed = menuItemGetUserConfirmation(
      `mark as ${update} selected`,
      `Are you sure you would like to mark the selected role(s) as ${update}?`
    );
    if (!isConfirmed) return;

    const promises: Promise<boolean>[] = [];

    if (update === 'reviewed') {
      selectedRoles.forEach(roleId => {
        promises.push(handleRoleUpdate(roleId, { reviewed: true, needsApproval: false }));
      });
    } else if (update === 'unreviewed') {
      selectedRoles.forEach(roleId => {
        promises.push(handleRoleUpdate(roleId, { reviewed: false, needsApproval: false }));
      });
    }

    setIsRoleUpdateInProgress(true);
    const results = await Promise.all(promises);
    const addBenchmarkSkillsPromises: Promise<boolean>[] = [];
    results.forEach((success, index) => {
      if (success) {
        logAnalytics(`mark as ${update} successful`);
        if (update === 'reviewed') {
          const addBenchmarkSkillsPromise = RolesService.addBenchmarkSkills(companyId, selectedRoles[index].toString());
          addBenchmarkSkillsPromises.push(addBenchmarkSkillsPromise);
        }
      } else {
        logAnalytics(`marks as ${update} failed`);
      }
    });
    await Promise.all(addBenchmarkSkillsPromises);

    await reloadRolesAfterBulkChanges(results, 'updated');
  };

  const handleAddSkillsClick = () => {
    setUpdateType(RoleSkillUpdateType.Add);
    setRoleSkillsUpdateInProgress(true);
  };

  const handleRemoveSkillsClick = () => {
    setUpdateType(RoleSkillUpdateType.Remove);
    setRoleSkillsUpdateInProgress(true);
  };

  const handleDeleteClick = async (roleIds: string[] | GridRowId[]) => {
    const isConfirmed = menuItemGetUserConfirmation(
      'delete selected',
      'Are you sure you would like to delete selected role(s)?'
    );
    if (!isConfirmed) return;

    const promises: Promise<any>[] = [];

    roleIds.forEach(roleId => {
      promises.push(handleRoleDelete(roleId));
    });

    setIsRoleUpdateInProgress(true);
    const results = await Promise.all(promises);
    results.forEach(success => {
      logAnalytics(success ? 'delete successful' : 'delete failed');
    });

    await reloadRolesAfterBulkChanges(results, 'deleted');
  };

  const roleCriteriaSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('rf', roleFilter);
    searchParams.set('af', JSON.stringify(appliedFilters));
    searchParams.set('p', currentPage);
    searchParams.set('n', rowsPerPage);
    sortModel[0]?.field && searchParams.set('sc', sortModel[0].field);
    sortModel[0]?.sort && searchParams.set('sd', sortModel[0].sort);
    const searchParamsString = searchParams.toString();

    if (searchParamsString.length > window.location.search.length) {
      ReactGA.event({
        category: 'roles',
        action: 'roles criteria length',
        label: searchParamsString.length.toString(),
      });
    }

    const newRelativePathQuery = `${window.location.pathname}?${searchParamsString}`;
    window.history.pushState(null, '', newRelativePathQuery);
    return searchParams;
  }, [appliedFilters, currentPage, roleFilter, rowsPerPage, sortModel]);

  const handleRoleClick = roleId => {
    navigate(`/role-edit/${roleId}?${roleCriteriaSearchParams.toString()}`);
  };

  const handleCopyRoleClick = async roleId => {
    setActionRoleId(roleId);
    setIsRoleCopy(true);
    setEditModalIsOpen(true);
  };

  const pageChangeHandler = (): void => {
    setIsPageChanged(!isPageChanged);
  };

  const sortRoles = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    setCurrentPage(0);
  };

  const handleClearAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (appliedFiltersCopy[area]) {
      delete appliedFiltersCopy[area];
      setCurrentPage(0);
      setAppliedFilters(appliedFiltersCopy);
    }
  };

  const handleSelectAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };
    const totalFacetsCopy = { ...cloneDeep(totalFacets) };
    appliedFiltersCopy[area] = totalFacetsCopy[area];
    setCurrentPage(0);
    setAppliedFilters(appliedFiltersCopy);
  };

  const handleSuccessfulRoleCopy = async () => {
    setLoadingRoles(true);
    await loadRoles();
    setLoadingRoles(false);
  };

  const handleExportSelected = (exportArea: ExportArea) => {
    const idsToExport = selectedRoles.map(sr => sr.toString());
    triggerFileExport(exportArea, idsToExport);
  };

  const buttonGroups: EMSIBGButton[] = [
    {
      text: `${selectedRoles.length} Selected`,
      color: 'actionSecondary',
      onClickEvent: (e: React.MouseEvent<HTMLElement>) => setMenuItemsAnchor(e.currentTarget),
      variant: 'outlined',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      disabled: selectedRoles.length === 0,
      dataCy: 'roles-menu-button',
    },
    {
      text: exporting ? 'Exporting Data' : 'Export Data',
      color: 'actionSecondary',
      disabled: exporting,
      onClickEvent: (e: React.MouseEvent<HTMLElement>) => setExportItemsMenuAnchor(e.currentTarget),
      variant: 'outlined',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      dataCy: 'roles-menu-button',
    },
    {
      text: 'Add Role',
      color: 'actionPrimary',
      onClickEvent: () => setUploadModalIsOpen(true),
      variant: 'contained',
      visible: !isAnalystReadOnly(),
      dataCy: 'roles-add-button',
    },
  ];

  const ActionsComponent = useMemo((): ReactElement => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Menu anchorEl={menuItemsAnchor} open={menuOpen} onClose={handleMenuClose} data-cy="roles-menu-items">
          {MenuItemWithIcon(
            'Compare',
            selectedRoles.length > 3,
            compareRoles,
            'roles-menu-compare-button',
            'Compare up to 3 roles',
            IconType.InformationFill,
            selectedRoles.length > 3 ? IconColorFilter.Warning : IconColorFilter.Default
          )}
          <MenuItem
            data-cy="roles-menu-duplicate-button"
            disabled={isAnalystReadOnly() || !(selectedRoles.length === 1)}
            onClick={() => {
              handleCopyRoleClick(selectedRoles[0]);
              handleMenuClose();
            }}
          >
            Duplicate
          </MenuItem>
          <MenuItem
            disabled={isAnalystReadOnly()}
            data-cy="roles-menu-request-approval-button"
            onClick={handleRequestApprovalClick}
          >
            Request Approval
          </MenuItem>
          <MenuItem
            disabled={isAnalystReadOnly()}
            data-cy="roles-menu-mark-reviewed-button"
            onClick={async () => handleRoleStatusUpdates('reviewed')}
          >
            Mark as Reviewed
          </MenuItem>
          <MenuItem
            disabled={isAnalystReadOnly()}
            data-cy="roles-menu-mark-unreviewed-button"
            onClick={async () => handleRoleStatusUpdates('unreviewed')}
          >
            Mark as Unreviewed
          </MenuItem>
          <MenuItem
            data-cy="roles-menu-update-mappings-button"
            disabled={isAnalystReadOnly()}
            onClick={() => {
              setUpdateMappingsModalIsOpen(true);
              handleMenuClose();
            }}
          >
            Update Mappings
          </MenuItem>
          <MenuItem
            disabled={isAnalystReadOnly()}
            data-cy="roles-menu-delete-button"
            onClick={() => handleDeleteClick(selectedRoles)}
          >
            Delete
          </MenuItem>
          <MenuItem
            data-cy="roles-menu-export-selected-button"
            onClick={() => {
              handleExportSelected('roles');
              handleMenuClose();
            }}
          >
            Export Selected Roles
          </MenuItem>
          <MenuItem
            data-cy="roles-menu-export-selected-tags-aliases-button"
            onClick={() => {
              handleExportSelected('tags and aliases');
              handleMenuClose();
            }}
          >
            Export Selected Tags/Aliases
          </MenuItem>
          <MenuItem
            data-cy="roles-menu-add-skills-button"
            onClick={async () => {
              handleAddSkillsClick();
              handleMenuClose();
            }}
          >
            Add Skills
          </MenuItem>
          <MenuItem
            data-cy="roles-menu-remove-skills-button"
            onClick={async () => {
              handleRemoveSkillsClick();
              handleMenuClose();
            }}
          >
            Remove Skills
          </MenuItem>
        </Menu>
        <ButtonGroup spacing={1} buttons={buttonGroups} wrap="nowrap" justifyContent="flex-end" />
      </Box>
    );
  }, [roleFilter, appliedFilters, buttonGroups]);

  const ExportActionsComponent = useMemo((): ReactElement => (
    <Menu anchorEl={exportItemsMenuAnchor} open={exportItemsMenuOpen} onClose={handleMenuClose} data-cy="roles-menu-items">
      <MenuItem
        data-cy='roles-export-button'
        disabled={exporting}
        onClick={() => {
          triggerFileExport('roles');
          handleMenuClose();
        }}
      >
        Export Role Data
      </MenuItem>
      <MenuItem
        data-cy='tags-aliases-export-button'
        disabled={exporting}
        onClick={() => {
          triggerFileExport('tags and aliases');
          handleMenuClose();
        }}
      >
        Export Tags/Aliases
      </MenuItem>
    </Menu>
  ), [buttonGroups, exportItemsMenuOpen]);

  const columnCellContent = (id: string | number, value: string, dataCyCategory: string) => {
    return (
      <EllipsizeText data-cy={`roles-row-${dataCyCategory}-${id}`} title={value === 'n/a' ? 'Not mapped' : value}>
        {value}
      </EllipsizeText>
    );
  };

  const columns: GridColDef<Role>[] = [
    {
      field: 'roleName',
      headerName: 'Role Name',
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-role-name-header',
      renderCell: param => {
        return (
          <div style={{ display: 'block', flexDirection: 'row' }}>
            <Button
              variant="text"
              id="role-title"
              data-cy={`roles-row-role-name-${param.id}`}
              onClick={() => handleRoleClick(param.id)}
            >
              {param.value}
            </Button>
          </div>
        );
      },
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-job-title-header',
      renderCell: param => {
        return columnCellContent(param.id, param.value, 'job-title');
      },
    },
    {
      field: 'emsiTitle',
      headerName: 'Mapped Title',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-emsi-title-header',
      renderCell: param => {
        return columnCellContent(param.id, param.value.name, 'emsi-title');
      },
    },
    {
      field: 'specializedOccupationName',
      headerName: 'Specialized Occupation',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-specialized-occupation-header',
      renderCell: param => {
        return columnCellContent(param.id, param.value, 'specialized-occupation-name');
      },
    },
    {
      field: 'socName',
      headerName: 'SOC Occupation',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-soc-header',
      renderCell: param => {
        return columnCellContent(param.id, param.value, 'soc-name');
      },
    },
    {
      field: 'soc',
      headerName: 'SOC Code',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'roles-table-soc-header',
      renderCell: param => {
        return columnCellContent(param.id, param.value, 'soc');
      },
    },
    {
      field: 'reviewStatus',
      headerName: 'Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'roles-table-mapping-status-header',
      renderCell: param => {
        return <RoleReviewStatusLabel status={param.value} />;
      },
    },
    {
      field: 'jobFamily',
      headerName: 'Job Family',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-family-header',
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'roles-table-last-updated-header',
      renderCell: param => {
        return (
          <LastUpdatedColumnContainer>
            {dayjs(param.value).format('M/D/YY')}
            <IconContainer>
              <BaseIcon
                type={IconType.Edit}
                onIconClick={() => openEditModal(param.id)}
                dataCy={`roles-row-edit-icon-${param.id}`}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.None}
              />
              <BaseIcon
                type={IconType.Copy}
                onIconClick={() => handleCopyRoleClick(param.id)}
                dataCy={`roles-row-copy-icon-${param.id}`}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.None}
              />
              <BaseIcon
                type={IconType.FileListLine}
                onIconClick={() => openViewLogModal(param.id)}
                dataCy={`roles-row-view-log-icon-${param.id}`}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.None}
              />
              <BaseIcon
                type={IconType.Delete}
                onIconClick={async () => await handleDeleteClick([param.id])}
                dataCy={`roles-row-delete-icon-${param.id}`}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.None}
              />
            </IconContainer>
          </LastUpdatedColumnContainer>
        );
      },
    },
  ];
  const rows = augmentedRoles;

  useEffect(() => {
    (async () => {
      if (clickedRoleId) {
        const role = await RolesService.getRole(companyId, clickedRoleId.toString());
        setClickedRole(role);
      }
    })();
  }, [clickedRoleId]);

  return (
    <>
      <MainContentTemplate pageTitle="Applied Research Tools">
        <ContentHeaderTemplate
          contentTitle="Applied Research Tools"
          actionsComponent={ActionsComponent}
          subNavComponent={
            <HeaderSubnavContainer>
              <RolesSearchBar
                roleFilter={roleFilter}
                setRoleFilter={handleRoleFilterChange}
                totalFacets={totalFacets}
                handleToggle={handleToggle}
                appliedFilters={appliedFilters}
                handleClearAllFacets={handleClearAllFacets}
                handleSelectAllFacets={handleSelectAllFacets}
              />
              <Pagination className="pagination-top">
                <TablePagination
                  dataCy="roles-list-table-pagination"
                  total={totalRoleCount}
                  page={currentPage}
                  setPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  customRowsPerPageOptions={[25, 50, 100]}
                  setRowsPerPage={setRowsPerPage}
                  pageChangeHandler={pageChangeHandler}
                  justifyContent="flex-end"
                />
              </Pagination>
            </HeaderSubnavContainer>
          }
        />
        <MainContentWrapper data-cy="main-content-ar-tools">
          <div>
            <RolesContainer>
              <DataGrid
                columns={columns}
                rows={loadingRoles ? [] : rows}
                getRowId={(r: Role) => r.roleId}
                loading={loadingRoles || isRoleUpdateInProgress}
                sx={{
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellCheckbox, & .MuiDataGrid-columnHeaderCheckbox': {
                    pointerEvents: isRoleUpdateInProgress ? 'none' : 'auto',
                  },
                }}
                pagination
                paginationMode="server"
                rowCount={totalRoleCount}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
                hideFooterPagination={true}
                hideFooter={true}
                autoHeight
                onRowClick={handleRowClick}
                checkboxSelection
                disableSelectionOnClick
                selectionModel={selectedRoles}
                onSelectionModelChange={selectRoleHandler}
                sortingMode="server"
                sortingOrder={['asc', 'desc']}
                sortModel={sortModel}
                onSortModelChange={sortRoles}
                className="roles-list-table"
                components={{
                  NoRowsOverlay: () => <></>,
                  LoadingOverlay: isRoleUpdateInProgress ? LinearProgress : undefined,
                }}
              />

              {!loadingRoles && hasFilters && roles.length === 0 && (
                <div
                  data-cy="roles-table-body-no-filtered-roles-message"
                  style={{
                    textAlign: 'center',
                    color: theme.colors.text.disabled,
                    fontWeight: 'bold',
                    paddingTop: `${theme.customSpacing.px.base * 8}px`,
                  }}
                >
                  no roles found that match your search criteria
                </div>
              )}

              {roles.length <= 0 && !loadingRoles && !hasFilters && (
                <NoRolesContainer $backgroundImageUrl={theme.brand.noRolesBackground}>
                  <NoRolesMessage>
                    <h4>No internal roles, yet</h4>
                    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                      <Button variant="text" onClick={() => setUploadModalIsOpen(true)} sx={{ paddingLeft: '0px' }}>
                        Add Roles
                      </Button>{' '}
                      here manually or by uploading a CSV.
                    </div>
                  </NoRolesMessage>
                </NoRolesContainer>
              )}
              <DialogModal
                dialogOpen={showRoleDetailsDialog}
                disableEscapeKeyDown={false}
                closeModal={handleRoleDetailsDialogClose}
                fullWidth={true}
                showDialogTitle={false}
                content={
                  <>
                    <Typography variant={'body'} sx={{ padding: `${theme.customSpacing.px.xs}px` }} component={'div'}>
                      <div>
                        <b>Job Title:</b> <span>{clickedRole ? clickedRole.jobTitle : <Skeleton />}</span>
                      </div>
                    </Typography>
                    <Typography variant={'body'} sx={{ padding: `${theme.customSpacing.px.xs}px` }} component={'div'}>
                      <div>
                        <b>Job Code:</b> <span>{clickedRole ? clickedRole.jobCode : <Skeleton />}</span>
                      </div>
                    </Typography>
                    <Typography variant={'body'} sx={{ padding: `${theme.customSpacing.px.xs}px` }} component={'div'}>
                      <div>
                        <b>Job Description:</b> <span>{clickedRole ? clickedRole.description : <Skeleton />}</span>
                      </div>
                    </Typography>
                  </>
                }
              />
            </RolesContainer>
          </div>
          <Pagination className="pagination-bottom">
            <TablePagination
              dataCy="roles-list-table-pagination-bottom"
              total={totalRoleCount}
              page={currentPage}
              setPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              customRowsPerPageOptions={[25, 50, 100]}
              pageChangeHandler={pageChangeHandler}
            />
          </Pagination>
        </MainContentWrapper>
        <RoleEditContextProvider roleId={actionRoleId} isCopying={isRoleCopy}>
          <RoleEditModal
            isOpen={editModalIsOpen}
            closeHandler={closeEditModal}
            callCopyRoleSuccessHandler={handleSuccessfulRoleCopy}
            shouldClearRoleAfter={true}
          />
        </RoleEditContextProvider>
        <RoleUploadFileModal
          isOpen={uploadModalIsOpen}
          closeHandler={(openAddModal = false) => {
            setUploadModalIsOpen(false);
            setActionRoleId(undefined);
            setEditModalIsOpen(openAddModal);
          }}
        />
        <RoleViewLogModal
          isOpen={viewLogModalIsOpen}
          closeHandler={() => closeViewLogModal()}
          roleId={actionRoleId || ''}
          // TODO: Modal title is not finalized. Need to update here once it is finalized.
          modalTitle={'Role Activity'}
          sourceComponent={'roles'}
        />
        <RoleUpdateMappingsModal
          isOpen={updateMappingsModalIsOpen}
          closeHandler={closeUpdateMappingsModal}
          roles={roles.filter(role => selectedRoles.includes(role.roleId))}
          sourceComponent={'ar-tools'}
        />
      </MainContentTemplate>
      <DialogModal
        title={`${updateType === RoleSkillUpdateType.Add ? 'Add' : 'Remove'} Skills`}
        dialogOpen={roleSkillsUpdateInProgress}
        content={
          <QuickUpdateSkills
            roleIds={selectedRoles.map(roleRowId => {
              return roleRowId.toString();
            })}
            onCancel={() => setRoleSkillsUpdateInProgress(false)}
            onSave={saveManuallyUpdatedSkills}
            actionButtonText={`${updateType === RoleSkillUpdateType.Add ? 'Add' : 'Remove'} Skills`}
          />
        }
        closeModal={() => setRoleSkillsUpdateInProgress(false)}
        buttonGroup={[]}
        maxWidth="md"
        fullWidth={true}
        dataCy="ar-tools-page-skill-update-modal"
      />
      {ExportActionsComponent}
    </>
  );
};

export default RolesARToolsPage;
