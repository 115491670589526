import React, { useState, useEffect, useMemo, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';

import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';

import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import SingleRoleMappingModal from 'components/organisms/SingleRoleMappingModal';
import SkillTableSelect from 'components/molecules/SkillTableSelect';
import RoleViewLogModal from 'components/organisms/modals/RoleViewLogModal';
import RoleMetadataHorizontal from 'components/molecules/RoleMetadataHorizontal';
import NoDataPlaceholder from 'components/atoms/NoDataPlaceholder';
import NavigationSwitcher from 'components/molecules/NavigationSwitcher';
import QuickUpdateSkills, { RoleSkillUpdateType } from 'components/organisms/QuickUpdateSkills';
import DialogModal from 'components/molecules/DialogModal';
import TableSelect from 'components/molecules/TableSelect';

import UseCompanyContext from 'helpers/UseCompanyContext';
import { getCompanySkills } from 'helpers/SkillAnalysisHelper';
import { badgeBuilder, lightcastSkillLink } from 'helpers/SkillProfilePageHelper';
import useAuthContext from 'helpers/UseAuthContext';
import useRoleSkillsUpdate from 'helpers/useRoleSkillsUpdate';

import RolesService from 'services/RolesService';
import SkillProfileService from 'services/SkillProfileService';
import Role from 'classes/role';
import SkillProfile, { Skill } from 'classes/skillProfile';
import { IconColorFilter, SkillPostingsPercentage, SkillSuggestionBy } from 'types/types';
import { RoleEditContextProvider } from 'contexts/RoleEditContext';

const skillNameStyle = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SkillNameAsLink = styled(Link)`
  ${skillNameStyle}
  cursor: pointer;
`;

const SkillNameAsSpan = styled.span`
  ${skillNameStyle}
`;

const ActionComponentsContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: right;

  .button-group {
    justify-content: flex-end;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }
`;

const StyledEmsiUIBadge = styled(EmsiUIBadge)`
  margin: 0.5rem 0;
`;

type ModalSkillOption = 'AddSkillManually' | 'AddCustomSkill';

interface PathParamsProps {
  roleId: string;
}
const SkillProfileDetailsPage = (): ReactElement => {
  const { isAnalystReadOnly } = useAuthContext();
  const { companyId, companyPostingsId, getCompanySkillLevelOptions } = UseCompanyContext();
  const navigate = useNavigate();
  const { roleId: id } = useParams<keyof PathParamsProps>() as PathParamsProps;
  const [roleId, setRoleId] = useState(id);

  const [skillProfile, setSkillProfile] = useState<Role>(new Role({}));
  const [skillProfileSkills, setSkillProfileSkills] = useState<SkillProfile['skills']>([]);
  const [totalSkillProfileSkillsCount, setTotalSkillProfileSkillsCount] = useState(0);
  const [loadingSkillProfile, setLoadingSkillProfile] = useState(true);
  const [loadingSkillProfileSkills, setLoadingSkillProfileSkills] = useState(true);
  const [viewLogModalIsOpen, setViewLogModalIsOpen] = useState(false);
  const [mappingModalIsOpen, setMappingModalIsOpen] = useState(false);
  const [selectedSkillProfileSkills, setSelectedSkillProfileSkills] = useState<string[]>([]);
  const [selectedSkillsMenuItemsAnchor, setSelectedSkillsMenuItemsAnchor] = useState<null | HTMLElement>(null);
  const selectedSkillsMenuOpen = Boolean(selectedSkillsMenuItemsAnchor);
  const [addSkillsMenuItemsAnchor, setAddSkillsMenuItemsAnchor] = useState<null | HTMLElement>(null);
  const addSkillsMenuOpen = Boolean(addSkillsMenuItemsAnchor);
  const BENCHMARK_SKILLS_LIMIT = 100;
  const [isLoadingBenchmarkSkillsPercentage, setIsLoadingBenchmarkSkillsPercentage] = useState<boolean>(false);
  const [isHavingBenchmark, setIsHavingBenchmark] = useState<boolean>(true);
  const [benchmarkSkillsPercentage, setBenchmarkSkillsPercentage] = useState<SkillPostingsPercentage[]>([]);
  const [isAddingTopBenchmarkSkills, setIsAddingTopBenchmarkSkills] = useState<boolean>(false);
  const [ourPostingsSkillsPercentage, setOurPostingsSkillsPercentage] = useState<SkillPostingsPercentage[]>([]);
  const [isLoadingOurPostingsSkillsPercentage, setIsLoadingOurPostingsSkillsPercentage] = useState<boolean>(false);

  const {
    roleSkillsUpdated,
    updateType,
    setAppArea,
    setUpdateType,
    roleSkillsUpdateInProgress,
    setRoleSkillsUpdateInProgress,
    saveManuallyUpdatedSkills,
  } = useRoleSkillsUpdate();

  const [skillModalType, setSkillModalType] = useState<ModalSkillOption | undefined>();
  const skillLevelOptions = getCompanySkillLevelOptions();

  const loadSkillProfileSkills = async () => {
    const role = await RolesService.getRole(companyId, roleId);
    const { skillProfile, totalCount } = await SkillProfileService.getSkillProfile({ companyId, roleId });
    setSkillProfile(role);
    setSkillProfileSkills(skillProfile?.skills);
    setTotalSkillProfileSkillsCount(totalCount);
    setIsAddingTopBenchmarkSkills(false);
  };

  const setNewRoleId = (newRoleId: string) => {
    window.history.replaceState('', '', `/skill-profiles/${newRoleId}`);
    setRoleId(newRoleId);
  };
  const handleSkillProfileSelectionChange = (newRoleId: string) => {
    setNewRoleId(newRoleId);
  };

  useEffect(() => {
    // This page currently only uses Add skill functionality, so we'll set this once.
    setUpdateType(RoleSkillUpdateType.Add);
    setAppArea('Skill Profile Details Page');
  }, []);

  useEffect(() => {
    const getSkillProfileSkills = async () => {
      setLoadingSkillProfile(true);
      setLoadingSkillProfileSkills(true);
      roleSkillsUpdated && (await loadSkillProfileSkills());
      setLoadingSkillProfile(false);
      setLoadingSkillProfileSkills(false);
    };

    getSkillProfileSkills();
  }, [roleId, roleSkillsUpdated]);

  const logError = (error: Error) => {
    Sentry.captureException(error);
    ReactGA.event({
      category: 'Skill Profile details',
      action: 'get our postings percentage',
      label: error.message,
    });
  };

  const loadOurPostingSkillsPercentages = async () => {
    setIsLoadingOurPostingsSkillsPercentage(true);
    try {
      // Call getCompanySkills to get the skills percentage for our postings.
      const { totalCompanyPostings, skills: companySkills } = await getCompanySkills(companyPostingsId, {
        title: [skillProfile.emsiTitleId],
      });
      const postingSkillsPercentage: Array<SkillPostingsPercentage> = companySkills.map(skillPostings => {
        return {
          skillId: skillPostings.name,
          percentage: Math.round((skillPostings.unique_postings / totalCompanyPostings) * 100) / 100,
        };
      });
      setOurPostingsSkillsPercentage(postingSkillsPercentage);
    } catch (e: unknown) {
      const error = e as Error;
      logError(error);
    } finally {
      setIsLoadingOurPostingsSkillsPercentage(false);
    }
  };

  const loadBenchmarkSkillsPercentage = async () => {
    setIsLoadingBenchmarkSkillsPercentage(true);
    try {
      const benchmarkSkills = await SkillProfileService.getSkillProfileBenchmarkMatchBySkills(
        companyId,
        roleId,
        BENCHMARK_SKILLS_LIMIT
      );
      setIsHavingBenchmark(benchmarkSkills.hasBenchmark);
      setBenchmarkSkillsPercentage(benchmarkSkills.benchmarkSkillsMatchPercentage);
    } catch (e) {
      const error = e as Error;
      logError(error);
    } finally {
      setIsLoadingBenchmarkSkillsPercentage(false);
    }
  };

  useEffect(() => {
    (async () => {
      await loadBenchmarkSkillsPercentage();
      await loadOurPostingSkillsPercentages();
    })();
  }, [skillProfileSkills]);

  const updateSkill = async skill => {
    const success = await RolesService.patchRoleSkill(companyId, roleId, skill);
    await loadSkillProfileSkills();
    if (!success) {
      alert('Updating skills failed. Please try again.');
    }
    ReactGA.event({
      category: 'skill profiles',
      action: 'update skill',
      label: success ? 'success' : 'failed',
    });
  };

  const deleteSkill = async skillId => {
    const success = await RolesService.deleteRoleSkills(companyId, roleId, [skillId]);
    await loadSkillProfileSkills();
    if (!success) {
      alert('Deleting skills failed. Please try again.');
    }
    ReactGA.event({
      category: 'skill profiles',
      action: 'delete skill',
      label: success ? 'success' : 'failed',
    });
  };

  const selectionRowChange = (selectedRowIds: any[]) => {
    setSelectedSkillProfileSkills(selectedRowIds);
  };

  const handleMenuClose = (menuItem: HTMLElement | null) => {
    switch (menuItem) {
      case selectedSkillsMenuItemsAnchor:
        setSelectedSkillsMenuItemsAnchor(null);
        break;
      case addSkillsMenuItemsAnchor:
        setAddSkillsMenuItemsAnchor(null);
        break;
    }
  };

  const styledColumnHeader = (header: string) => {
    return <Typography variant="strong">{header}</Typography>;
  };

  const columns: GridColDef<Skill>[] = [
    {
      field: 'skillName',
      renderHeader: () => styledColumnHeader('Skill Name'),
      flex: 1,
      renderCell: param => {
        return !param.row.customSkillId ? (
          <SkillNameAsLink href={lightcastSkillLink(param.id.toString())} target="_blank">
            {param.value}
          </SkillNameAsLink>
        ) : (
          <SkillNameAsSpan>{param.value}</SkillNameAsSpan>
        );
      },
    },
    {
      field: 'typeName',
      renderHeader: () => styledColumnHeader('Type'),
      flex: 0.75,
    },
    {
      field: 'categoryName',
      renderHeader: () => styledColumnHeader('Category'),
      flex: 1,
    },
    {
      field: 'subcategoryName',
      renderHeader: () => styledColumnHeader('Subcategory'),
      flex: 1,
    },
    {
      // We are making the same JPA call as for Benchmarks, but only with the actual companyPostingsId (not benchmarks ones)
      // and we're also only filtering to the skills we've saved, otherwise the value will be 0%
      field: 'ourPostingsPercentage',
      renderHeader: () => styledColumnHeader('% in Our Postings'),
      flex: 1,
      renderCell: param => {
        const ourPostingSkillPercentage = ourPostingsSkillsPercentage.find(
          item => item.skillId === param.row.skillId
        )?.percentage;
        return (
          <>
            {
              // If show skeleton whilst loading
              isLoadingOurPostingsSkillsPercentage && <Skeleton variant="text" width={50} />
            }
            {
              // If this is a custom skill, we show 'n/a' as we can't calculate for those.
              // If we have the percentage, build the badge, else show 0% for Lightcast skills that don't appear in our postings/
              !isLoadingOurPostingsSkillsPercentage &&
                (param.row.customSkillId ? (
                  <StyledEmsiUIBadge
                    color="surfaceDisabled"
                    label="n/a"
                    title="No posting % available for custom skills"
                  />
                ) : ourPostingSkillPercentage ? (
                  badgeBuilder(ourPostingSkillPercentage)
                ) : (
                  <StyledEmsiUIBadge color="surfaceCritical" label="0%" title="Not found in postings" />
                ))
            }
          </>
        );
      },
    },
    {
      field: 'benchmarkPostingsPercentage',
      renderHeader: () => styledColumnHeader('% in Benchmarks'),
      flex: 0.75,
      renderCell: param => {
        // if we are not loading and have a benchmark (isHavingBenchmark) we then check if we found a benchmark value and display it, else show N/A
        const benchmarkSkillPercentage = benchmarkSkillsPercentage.find(
          item => item.skillId === param.row.skillId
        )?.percentage;
        return (
          <>
            {isLoadingBenchmarkSkillsPercentage ? (
              <Skeleton variant="text" width={50} />
            ) : isHavingBenchmark ? (
              benchmarkSkillPercentage ? (
                badgeBuilder(benchmarkSkillPercentage / 100)
              ) : (
                <StyledEmsiUIBadge
                  color="surfaceDisabled"
                  label="n/a"
                  title={`Not present in top ${BENCHMARK_SKILLS_LIMIT} benchmark skills`}
                />
              )
            ) : (
              <StyledEmsiUIBadge color="surfaceDisabled" label="n/a" title="No benchmarks available to load skills" />
            )}
          </>
        );
      },
    },
    {
      field: 'skillLevel',
      renderHeader: () => styledColumnHeader('Skill Level'),
      flex: 0.75,
      renderCell: param => {
        const [updatingSkillLevel, setUpdatingSkillLevel] = useState(false);
        return (
          <TableSelect
            field={{ id: param.row.skillId, value: param.row.skillLevel.toString() }}
            options={skillLevelOptions}
            key={param.row.skillId}
            updateHandler={async e => {
              try {
                setUpdatingSkillLevel(true);
                const skillLevel = parseInt(e.target.value as string);
                await updateSkill({ ...param.row, skillLevel });
              } finally {
                setUpdatingSkillLevel(false);
              }
            }}
            isUpdating={updatingSkillLevel}
          />
        );
      },
    },
    {
      field: 'required',
      renderHeader: () => styledColumnHeader('Required'),
      flex: 0.75,
      renderCell: param => {
        return (
          <SkillTableSelect
            skill={{ skillId: param.row.skillId, skillName: param.row.skillName, required: param.row.required }}
            updateHandler={updateSkill}
            deleteHandler={deleteSkill}
          />
        );
      },
    },
  ];

  const rows: Skill[] = sortBy(skillProfileSkills, skill => {
    return skill.skillName;
  })?.map(skill => {
    return {
      id: skill.skillId,
      ...skill,
    };
  });

  const buttonGroups: EMSIBGButton[] = [
    {
      text: `Last Updated: ${dayjs(skillProfile.updatedAt).format('DD-MM-YYYY')}`,
      variant: 'text',
      disabled: true,
      visible: !loadingSkillProfile,
      size: 'small',
      dataCy: 'skillprofile-skills-last-updated-button',
    },
    {
      text: 'View Log',
      variant: 'outlined',
      disabled: loadingSkillProfile,
      onClickEvent: () => {
        setViewLogModalIsOpen(true);
        ReactGA.event({
          category: 'skill profile management',
          action: 'event logs',
          label: 'show',
        });
      },
      dataCy: 'skillprofile-skills-viewlog-button',
    },
    {
      text: 'Edit Role',
      variant: 'outlined',
      disabled: loadingSkillProfile,
      onClickEvent: () => setMappingModalIsOpen(true),
      dataCy: 'skillprofile-skills-edit-role-button',
    },
    {
      text: `${selectedSkillProfileSkills.length} Selected`,
      variant: 'outlined',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      disabled: loadingSkillProfile || selectedSkillProfileSkills.length === 0,
      onClickEvent: (event: React.MouseEvent<HTMLElement>) => setSelectedSkillsMenuItemsAnchor(event.currentTarget),
      dataCy: 'skillprofile-skills-menu-button',
    },
    {
      text: 'Add Skills',
      color: 'actionPrimary',
      variant: 'contained',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" colorFilter={IconColorFilter.OnPrimary} />,
      disabled: loadingSkillProfile || isAnalystReadOnly(),
      onClickEvent: (event: React.MouseEvent<HTMLElement>) => setAddSkillsMenuItemsAnchor(event.currentTarget),
      dataCy: 'skillprofile-skills-add-button',
    },
  ];

  const handleDeleteSkills = async () => {
    handleMenuClose(selectedSkillsMenuItemsAnchor);
    const selectedSkillProfileSkillsCount = selectedSkillProfileSkills.length;
    ReactGA.event({
      category: 'skill profile management',
      action: 'delete',
      label:
        (selectedSkillProfileSkillsCount === totalSkillProfileSkillsCount ? 'All - ' : '') +
        `${selectedSkillProfileSkillsCount} skills`,
    });
    try {
      setLoadingSkillProfileSkills(true);
      const deleteSuccess = await RolesService.deleteRoleSkills(companyId, roleId, selectedSkillProfileSkills);
      if (deleteSuccess) {
        const nonDeletedSkills = skillProfileSkills.filter(
          skill => !selectedSkillProfileSkills.includes(skill.skillId)
        );
        setSkillProfileSkills(nonDeletedSkills);
        setTotalSkillProfileSkillsCount(nonDeletedSkills.length);
      }
    } catch (e) {
      alert('Unable to delete skills. Try again later.');
      console.error(e);
    } finally {
      setLoadingSkillProfileSkills(false);
    }
  };

  const handleSkillsSetProficiency = async (proficiency: 'Required' | 'Preferred') => {
    handleMenuClose(selectedSkillsMenuItemsAnchor);
    const selectedSkillProfileSkillsCount = selectedSkillProfileSkills.length;
    ReactGA.event({
      category: 'skill profile management',
      action: 'proficiency update',
      label:
        (selectedSkillProfileSkillsCount === totalSkillProfileSkillsCount ? 'All - ' : '') +
        `${proficiency.toLowerCase()} ${selectedSkillProfileSkillsCount} skills`,
    });
    const proficiencyUpdatedSkills = skillProfileSkills.map(skill => {
      return {
        ...skill,
        required: selectedSkillProfileSkills.includes(skill.skillId) ? proficiency === 'Required' : skill.required,
      };
    });
    try {
      setLoadingSkillProfileSkills(true);
      const updateSuccess = await RolesService.setRoleSkills(companyId, roleId, proficiencyUpdatedSkills);
      if (updateSuccess) {
        setSkillProfileSkills(proficiencyUpdatedSkills);
      }
    } catch (e) {
      alert('Unable to update skill proficiencies. Try again later.');
      console.error(e);
    } finally {
      setLoadingSkillProfileSkills(false);
    }
  };

  const handleAddSkills = (
    addSkillOption: 'All' | 'JobDescription' | 'Benchmark' | 'Recommended' | ModalSkillOption
  ) => {
    ReactGA.event({
      category: 'skill profiles',
      action: 'add skill by category',
      label: addSkillOption,
    });
    handleMenuClose(addSkillsMenuItemsAnchor);

    if (addSkillOption === 'AddSkillManually' || addSkillOption === 'AddCustomSkill') {
      setSkillModalType(addSkillOption);
      setRoleSkillsUpdateInProgress(true);
    } else {
      navigate(`/skill-profiles/${roleId}/edit`, { state: { mode: addSkillOption } });
    }
  };

  const addBenchmarkSkills = async () => {
    setIsAddingTopBenchmarkSkills(true);
    try {
      await RolesService.addBenchmarkSkills(companyId, skillProfile.id);
    } catch (e) {
      console.error('Unable to add benchmark skill. Try again later.');
      console.error(e);
      setIsAddingTopBenchmarkSkills(false);
    } finally {
      loadSkillProfileSkills();
    }
  };

  const ActionsComponent = useMemo((): ReactElement => {
    return (
      <ActionComponentsContainer
        sx={{ width: '100%', display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'right' }}
      >
        <Box sx={{ display: 'flex' }}>
          <Menu
            anchorEl={selectedSkillsMenuItemsAnchor}
            open={selectedSkillsMenuOpen}
            onClose={() => handleMenuClose(selectedSkillsMenuItemsAnchor)}
            data-cy="skillprofile-skills-selected-skills-menu-items"
          >
            <MenuItem data-cy="skillprofile-skills-menu-skills-delete-button" onClick={handleDeleteSkills}>
              Delete
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-skills-required-button"
              onClick={() => handleSkillsSetProficiency('Required')}
            >
              Set Required
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-skills-preferred-button"
              onClick={() => handleSkillsSetProficiency('Preferred')}
            >
              Set Preferred
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={addSkillsMenuItemsAnchor}
            open={addSkillsMenuOpen}
            onClose={() => handleMenuClose(addSkillsMenuItemsAnchor)}
            data-cy="skillprofile-skills-add-skills-menu-items"
          >
            <MenuItem
              data-cy="skillprofile-skills-menu-add-skills-manually-button"
              onClick={() => handleAddSkills('AddSkillManually')}
            >
              Quick Add
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-add-skills-all-skills-button"
              onClick={() => handleAddSkills('All')}
            >
              All Skills
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-add-skills-description-button"
              onClick={() => handleAddSkills('JobDescription')}
            >
              {SkillSuggestionBy.JobDescription}
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-add-skills-benchmark-button"
              onClick={() => handleAddSkills('Benchmark')}
            >
              {SkillSuggestionBy.Benchmark}
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-add-skills-recommended-button"
              onClick={() => handleAddSkills('Recommended')}
            >
              Occupation
            </MenuItem>
            <MenuItem
              data-cy="skillprofile-skills-menu-add-custom-skill-button"
              onClick={() => handleAddSkills('AddCustomSkill')}
            >
              Custom
            </MenuItem>
          </Menu>
          <ButtonGroup buttons={buttonGroups} />
        </Box>
      </ActionComponentsContainer>
    );
  }, [buttonGroups, skillProfileSkills]);

  const NoProfileSkillsMessage = (): ReactElement => {
    return (
      <NoDataPlaceholder isFullHeight={false}>
        <Typography sx={{ textAlign: 'center' }}>
          {(!skillProfile.reviewed || skillProfile.needsApproval) && (
            <>
              {`This role requires ${skillProfile.needsApproval ? 'Approval' : 'Review'} before viewing Profile.`}
              <br />
              <Button variant="text" onClick={() => setMappingModalIsOpen(true)}>
                {'View Role Mapping details'}
              </Button>
            </>
          )}
          {skillProfile.reviewed && !skillProfile.needsApproval && (
            <>
              <div>{'This role has been reviewed but does not have skills in Profile.'}</div>
              {benchmarkSkillsPercentage.length > 0 && !isAddingTopBenchmarkSkills && (
                <Button
                  variant="text"
                  onClick={() => addBenchmarkSkills()}
                  disabled={isAddingTopBenchmarkSkills}
                  hidden={isAddingTopBenchmarkSkills || skillProfileSkills.length > 0}
                >
                  {`Add top ${
                    benchmarkSkillsPercentage.length < 10 ? benchmarkSkillsPercentage.length : 10
                  } skills from Benchmark`}
                </Button>
              )}
              {isAddingTopBenchmarkSkills && <CircularProgress size={14} />}
            </>
          )}
        </Typography>
      </NoDataPlaceholder>
    );
  };

  return (
    <>
      <MainContentTemplate
        pageTitle="Skill Profile"
        topChildrenComponent={
          <NavigationSwitcher
            roleId={roleId}
            onSelectionChange={handleSkillProfileSelectionChange}
            backClickHandler={() => navigate(`/skill-profiles${window.location.search}`)}
            dataCy="navigation-switcher"
          />
        }
      >
        <ContentHeaderTemplate
          contentTitle={loadingSkillProfile ? 'Loading Skill Profile . . .' : skillProfile.roleName}
          contentSubTitle={loadingSkillProfile ? undefined : 'Skill Profile'}
          backClickHandler={() => navigate('/skill-profiles')}
          actionsComponent={ActionsComponent}
          displayDivider={true}
          fullWidthDivider={true}
          subNavComponent={<RoleMetadataHorizontal role={loadingSkillProfile ? undefined : skillProfile} />}
        />
        <DataGrid
          columns={columns}
          rows={rows}
          loading={loadingSkillProfileSkills}
          components={{
            NoRowsOverlay: () => <></>,
          }}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={selectionRowChange}
          className="skillprofile-skills-table"
        />
        {!loadingSkillProfileSkills && skillProfileSkills.length === 0 && NoProfileSkillsMessage()}
        <RoleViewLogModal
          isOpen={viewLogModalIsOpen}
          closeHandler={() => setViewLogModalIsOpen(false)}
          roleId={roleId}
          // TODO: Modal title is not finalized. Need to update here once it is finalized.
          modalTitle={'Role Activity'}
          sourceComponent={'skillprofile-skills'}
        />
        <RoleEditContextProvider roleId={roleId}>
          <SingleRoleMappingModal
            isOpen={mappingModalIsOpen}
            closeHandler={(reload = false) => {
              setMappingModalIsOpen(false);
              reload && loadSkillProfileSkills();
            }}
            refreshRoleHandler={loadSkillProfileSkills}
          />
        </RoleEditContextProvider>
      </MainContentTemplate>
      <DialogModal
        title="Quick-Add Skills"
        dialogOpen={roleSkillsUpdateInProgress && skillModalType === 'AddSkillManually'}
        content={
          <QuickUpdateSkills
            roleIds={[roleId]}
            onCancel={() => setRoleSkillsUpdateInProgress(false)}
            onSave={saveManuallyUpdatedSkills}
            actionButtonText={`${updateType === RoleSkillUpdateType.Add ? 'Add' : 'Remove'} Skills`}
          />
        }
        closeModal={() => setRoleSkillsUpdateInProgress(false)}
        buttonGroup={[]}
        maxWidth="md"
        fullWidth={true}
        dataCy="skill-profile-detail-page-skill-update-modal"
      />
      <DialogModal
        title="Add Custom Skills"
        dialogOpen={roleSkillsUpdateInProgress && skillModalType === 'AddCustomSkill'}
        content={
          <QuickUpdateSkills
            roleIds={[roleId]}
            onCancel={() => setRoleSkillsUpdateInProgress(false)}
            onSave={saveManuallyUpdatedSkills}
            actionButtonText={`${updateType === RoleSkillUpdateType.Add ? 'Add' : 'Remove'} Skills`}
            skillSource="custom"
          />
        }
        closeModal={() => setRoleSkillsUpdateInProgress(false)}
        buttonGroup={[]}
        maxWidth="md"
        fullWidth={true}
        dataCy="skill-profile-detail-page-skill-update-modal"
      />
    </>
  );
};

export default SkillProfileDetailsPage;
