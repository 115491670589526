import { DefaultTheme } from 'styled-components';
import { CustomThemeName } from 'types/types';

const globalStylesLightCast: DefaultTheme = {
  customThemeName: CustomThemeName.LightCast,
  brand: {
    svgLogo: '/images/talent-transform-lightcast-logo.svg',
    pngHomeBackground: '/images/InsightsHomeBackground-lightCast.png',
    noRolesBackground: '/images/no_roles-lightCast.png',
    headerBarColor1: '#FFFFFF',
    headerBarColor2: '#FFFFFF',
    get headerBarGradiant() {
      return `linear-gradient(92.12deg, ${this.headerBarColor1} 19.62%, ${this.headerBarColor2} 146.2%)`;
    },
  },
  customTypography: {
    fontFamily: 'TWK Lausanne',
    desktop: {
      displayXLarge: {
        size: '40px',
        weight: '600',
        lineHeight: '44px',
        letterSpacing: '-0.02em',
      },
      displayLarge: {
        size: '28px',
        weight: '600',
        lineHeight: '32px',
        letterSpacing: '-0.02em',
      },
      displayMedium: {
        size: '20px',
        weight: '500',
        lineHeight: '24px',
      },
      displaySmall: {
        size: '18px',
        weight: '500',
        lineHeight: '22px',
      },
      heading: {
        size: '16px',
        weight: '500',
        lineHeight: '24px',
      },
      subHeading: {
        size: '12px',
        weight: '400',
        lineHeight: '16px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
      },
      body: {
        size: '14px',
        weight: '400',
        lineHeight: '20px',
      },
      strong: {
        size: '14px',
        weight: '600',
        lineHeight: '20px',
      },
      caption: {
        size: '12px',
        weight: '400',
        lineHeight: '16px',
      },
      button: {
        size: '14px',
        weight: '500',
        lineHeight: '20px',
      },
    },
    mobile: {
      displayXLarge: {
        size: '28px',
        weight: '600',
        lineHeight: '36px',
        letterSpacing: '-0.02em',
      },
      displayLarge: {
        size: '24px',
        weight: '600',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
      },
      displayMedium: {
        size: '20px',
        weight: '600',
        lineHeight: '22px',
      },
      displaySmall: {
        size: '18px',
        weight: '500',
        lineHeight: '22px',
      },
      heading: {
        size: '16px',
        weight: '500',
        lineHeight: '24px',
      },
      subHeading: {
        size: '13px',
        weight: '400',
        lineHeight: '16px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
      },
      body: {
        size: '15px',
        weight: '400',
        lineHeight: '20px',
      },
      strong: {
        size: '15px',
        weight: '600',
        lineHeight: '20px',
      },
      caption: {
        size: '13px',
        weight: '400',
        lineHeight: '16px',
      },
      button: {
        size: '15px',
        weight: '500',
        lineHeight: '20px',
      },
    },
  },
  borderRadius: {
    small: '2px',
    default: '4px',
    medium: '6px',
    large: '8px',
    circle: '50%',
  },
  boxShadow: {
    default: '0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    surface1: '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.05)',
    surface2: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    surface3: '0px 0px 3px rgba(0, 0, 0, 0.2), 0px 4px 20px rgba(0, 0, 0, 0.1)',
    surface4: '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 8px 32px rgba(0, 0, 0, 0.3)',
    buttonFocus:
      '0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #4E91F5, 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    textFieldFocus:
      '0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #4E91F5, 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    surfaceSelected: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
  },
  colors: {
    background: {
      default: '#F7F7F7',
    },
    surface: {
      default: '#FFFFFF',
      selected: '#EDF3FF',
      disabled: '#F2F3F7',
      neutral: '#D8DBE1',
      neutralSubdued: '#F0F2F5',
      information: '#C4D4F5',
      informationSubdued: '#E0EBFF',
      success: '#C0F0D0',
      successSubdued: '#DCFAE6',
      warning: '#FAE5C8',
      warningSubdued: '#FCF4E8',
      critical: '#F8D7D7',
      criticalSubdued: '#FAEBEB',
    },
    text: {
      default: '#000000',
      subdued: '#454545',
      disabled: '#8A8A8A',
      information: '#2E5299',
      success: '#307848',
      warning: '#885304',
      critical: '#962639',
      onInteractive: '#FFFFFF',
      onPrimary: '#FFFFFF',
      onCritical: '#FFFFFF',
      interactive: '#3D6DCC',
      get onBackground() {
        return this.default;
      },
      get onHeaderBar() {
        return this.default;
      },
      get onHeaderBarAlt() {
        return this.disabled;
      },
      get onHeaderBarHover() {
        return this.default;
      },
    },
    border: {
      default: '#ACACAC',
      subdued: '#DEDEDE',
      hovered: '#C7C7C7',
      disabled: '#CCCCCC',
      information: '#3D6DCC',
      informationSubdued: '#BACAEB',
      success: '#40A060',
      successSubdued: '#B3EFC7',
      warning: '#A28241',
      warningSubdued: '#E8E0D4',
      critical: '#DA324D',
      criticalSubdued: '#E6D7DA',
    },
    icon: {
      // https://angel-rs.github.io/css-color-filter-generator/ to apply directly to <img/> using svg
      default: {
        hex: '#8B8D91',
        filter:
          'brightness(0) saturate(100%) invert(63%) sepia(13%) saturate(93%) hue - rotate(182deg) brightness(87%) contrast(82%)',
      },
      subdued: {
        hex: '#B2B4B9',
        filter:
          'brightness(0) saturate(100%) invert(82%) sepia(5%) saturate(190%) hue-rotate(185deg) brightness(86%) contrast(97%)',
      },
      hovered: {
        hex: '#95979B',
        filter:
          'brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(207%) hue-rotate(182deg) brightness(92%) contrast(83%)',
      },
      disabled: {
        hex: '#ADB0B8',
        filter:
          'brightness(0) saturate(100%) invert(79%) sepia(9%) saturate(194%) hue-rotate(186deg) brightness(88%) contrast(92%)',
      },
      information: {
        hex: '#3D6DCC',
        filter:
          'brightness(0) saturate(100%) invert(39%) sepia(45%) saturate(4018%) hue-rotate(209deg) brightness(88%) contrast(80%)',
      },
      success: {
        hex: '#40A060',
        filter:
          'brightness(0) saturate(100%) invert(51%) sepia(24%) saturate(952%) hue-rotate(87deg) brightness(98%) contrast(88%)',
      },
      warning: {
        hex: '#E0A42D',
        filter:
          'brightness(0) saturate(100%) invert(68%) sepia(56%) saturate(560%) hue-rotate(357deg) brightness(90%) contrast(95%)',
      },
      critical: {
        hex: '#DF5068',
        filter:
          'brightness(0) saturate(100%) invert(56%) sepia(50%) saturate(5536%) hue-rotate(320deg) brightness(91%) contrast(90%)',
      },
      onInteractive: {
        hex: '#FFFFFF',
        filter:
          'brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(452%) hue-rotate(32deg) brightness(115%) contrast(100%)',
      },
      onPrimary: {
        hex: '#FFFFFF',
        filter:
          'brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(452%) hue-rotate(32deg) brightness(115%) contrast(100%)',
      },
      onCritical: {
        hex: '#FFFFFF',
        filter:
          'brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(452%) hue-rotate(32deg) brightness(115%) contrast(100%)',
      },
      interactive: {
        hex: '#3D6DCC',
        filter:
          'brightness(0) saturate(100%) invert(39%) sepia(45%) saturate(4018%) hue-rotate(209deg) brightness(88%) contrast(80%)',
      },
      get onHeaderBar() {
        return this.default;
      },
    },
    action: {
      primary: '#3D6DCC',
      primaryHovered: '#2E5299',
      primaryPressed: '#1E3666',
      primaryDepressed: '#1E3666',
      primaryDisabled: '#E6E6E6',
      secondary: '#FFFFFF',
      secondaryHovered: '#F5F5F5',
      secondaryPressed: '#EBEBEB',
      secondaryDepressed: '#7A7A7A',
      secondaryDisabled: '#E6E6E6',
      critical: '#B81631',
      criticalHovered: '#991229',
      criticalPressed: '#751727',
      criticalDepressed: '#3D0710',
      criticalDisabled: '#DFE5E8',
    },
    interactive: {
      default: '#3D6DCC',
      defaultDisabled: '#80807C',
      critical: '#B81631',
      criticalDisabled: '#9E525F',
    },
    decorative: {
      brand: '#F54562',
      focus: '#4E91F5',
    },
    chart: {
      variant1: '#CA61FF',
      variant2: '#13BAC4',
      variant3: '#85D9A1',
      variant4: '#FFA163',
      variant5: '#0A5D62',
      variant6: '#CAA722',
      variant7: '#83ACFF',
      variant8: '#D8DBE1',
      variant9: '#93293B',
      variant10: '#FB7D91',
      variant11: '#18E8F5',
      variant12: '#4D88FF',
      variant13: '#F54562',
      variant14: '#0E8B93',
      get colorRange3() {
        return [this.variant1, this.variant2, this.variant3];
      },
      get colorRange7() {
        return [
          this.variant1,
          this.variant2,
          this.variant3,
          this.variant4,
          this.variant5,
          this.variant6,
          this.variant7,
        ];
      },
      get colorRange10() {
        return [
          this.variant1,
          this.variant2,
          this.variant3,
          this.variant4,
          this.variant5,
          this.variant6,
          this.variant11,
          this.variant12,
          this.variant13,
          this.variant14,
        ];
      },
    },
    progressBar: {
      default: '#FFA163',
      segment1: '#0A5D62',
      segment2: '#83ACFF',
      segment3: '#CAA722',
      segment4: '#93293B',
      segment5: '#CA61FF',
      segment6: '#FB7D91',
    },
    chart1: {
      purple: '#CA61FF',
      turquoise: '#7BD8DE',
      lightGreen: '#85D9A1',
    },
  },
  customSpacing: {
    px: {
      none: 0,
      base: 4,
      xxs: 8,
      xs: 12,
      s: 24,
      m: 40,
      l: 64,
      xl: 80,
    },
  },
};

export default globalStylesLightCast;
