import React, { ReactElement } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useTheme } from 'styled-components';
import { SuggestedSkill } from 'types/types';

type SkillInfoPopoverProps = {
  skillInfoAnchor: HTMLElement | null;
  handleSkillNamePopoverClose: () => void;
  skillInfo: SuggestedSkill;
};

const SkillInfoPopover = ({
  skillInfoAnchor,
  handleSkillNamePopoverClose,
  skillInfo,
}: SkillInfoPopoverProps): ReactElement => {
  const theme = useTheme();

  return (
    <Popover
      anchorEl={skillInfoAnchor}
      open={!!skillInfoAnchor}
      onClose={handleSkillNamePopoverClose}
      disableScrollLock={true}
      sx={{ pointerEvents: 'none' }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <div style={{ maxWidth: '420px', padding: `${theme.customSpacing.px.xs}px` }}>
        <Typography variant={'caption'} component={'div'}>
          Type: {skillInfo?.type?.name || 'N/A'}
        </Typography>
        <Typography variant={'caption'} component={'div'}>
          Category: {skillInfo?.category?.name || 'N/A'}
        </Typography>
        <Typography variant={'caption'} component={'div'}>
          Subcategory: {skillInfo?.subcategory?.name || 'N/A'}
        </Typography>
        <Typography variant={'caption'} component={'div'}>
          Is software: {'isSoftware' in skillInfo ? (skillInfo.isSoftware ? 'Yes' : 'No') : 'N/A'}
        </Typography>
      </div>
    </Popover>
  );
};

export default SkillInfoPopover;
