import React, { useState, useEffect, useContext } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import type { IntegrationCredentials, IntegrationSchedule, VendorCompany, VendorId } from 'types/types';
import axios from 'helpers/api_helper';
import CompanyService from 'services/CompanyService';
import { AuthenticationContext } from 'contexts/AuthenticationContext';
import BenchmarksService from 'services/BenchmarksService';
import useAuthContext from 'helpers/UseAuthContext';
import IntegrationService from 'services/IntegrationService';
import { Integration } from 'types/api_responses';

const companyOverrideInfoStorageKey = 'COMPANY_OVERRIDE_INFO';

export interface CompanyContextState {
  companyDetailsLoaded: boolean;
  clientId: string;
  companyId: string;
  companyName: string;
  safeCompanyName: string;
  subDomain: string;
  companyPostingsId: string;
  companyProfilesId: string;
  companyEmailDomains: string[];
  categories: any;
  categoriesTree: any;
  defaultBenchmark: any;
  benchmarksLoading: boolean;
  benchmarksLoaded: boolean;
  benchmarks: any[];
  rolesLoading: boolean;
  rolesLoaded: boolean;
  roles: any[];
  moreRolesAvailable: boolean;
  product_modules: string[];
  company_modifiers: string[];
  categoriesLoaded: boolean;
  vendorCompaniesWithConfiguration?: VendorCompany[];
  workdayVendorCompany?: VendorCompany;
  hasActiveVendorCompanyIntegration: boolean;
  integrationSchedules?: IntegrationSchedule[];
  integrationCredentials?: Record<VendorId, IntegrationCredentials>;
  refreshKey: number;
}

interface CompanyContextProviderProps {
  children?: React.ReactElement;
}

const defaultContext: CompanyContextState = {
  companyDetailsLoaded: false,
  companyId: '',
  clientId: '',
  companyName: '',
  safeCompanyName: '',
  subDomain: '',
  companyPostingsId: '',
  companyProfilesId: '',
  companyEmailDomains: [''],
  categories: {},
  categoriesTree: {},
  benchmarksLoading: false,
  benchmarksLoaded: false,
  benchmarks: [],
  defaultBenchmark: {},
  rolesLoading: false,
  rolesLoaded: false,
  roles: [],
  moreRolesAvailable: false,
  product_modules: [],
  company_modifiers: [],
  categoriesLoaded: false,
  vendorCompaniesWithConfiguration: undefined,
  workdayVendorCompany: undefined,
  hasActiveVendorCompanyIntegration: false,
  integrationSchedules: undefined,
  integrationCredentials: undefined,
  refreshKey: 0,
};

const CompanyContext = React.createContext<[CompanyContextState, React.Dispatch<React.SetStateAction<CompanyContextState>>, () => Promise<void> ]>([
  defaultContext,
  () => undefined,
  () => Promise.resolve(),
]);

const CompanyContextProvider: React.FC<CompanyContextProviderProps> = props => {
  const [authContext] = useContext(AuthenticationContext);
  const { isAnalyst, isAdmin } = useAuthContext();

  const [state, setState] = useState<CompanyContextState>({
    companyDetailsLoaded: false,
    companyId: authContext.companyId,
    clientId: '',
    companyName: '',
    safeCompanyName: '',
    subDomain: '',
    companyPostingsId: '',
    companyProfilesId: '',
    companyEmailDomains: [''],
    categories: {},
    categoriesTree: {},
    defaultBenchmark: {},
    benchmarksLoading: false,
    benchmarksLoaded: false,
    benchmarks: [],
    rolesLoading: false,
    rolesLoaded: false,
    roles: [],
    moreRolesAvailable: false,
    product_modules: [],
    company_modifiers: [],
    categoriesLoaded: false,
    refreshKey: Math.random(),
    vendorCompaniesWithConfiguration: undefined,
    workdayVendorCompany: undefined,
    hasActiveVendorCompanyIntegration: false,
    integrationSchedules: undefined,
    integrationCredentials: undefined,
  });

  const refreshContextState = async () => {
    if (!authContext.isLoggedIn || !state.companyId) return;

    setState((state: CompanyContextState) => ({ ...state, companyDetailsLoaded: false }));
    const company = await CompanyService.getCompany({ companyId: state.companyId });

    // track the custom dimension information in GA
    ReactGA.set({
      'Company Name': company.name,
    });

    const companyOverrideInfoString = window.sessionStorage.getItem(companyOverrideInfoStorageKey) || '{}';
    const companyOverrideInfo = JSON.parse(companyOverrideInfoString);

    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || 'GTMIDNOTLOADED',
      dataLayer: {
        customerId: authContext.companyId,
        viewingCustomerId: company.companyId,
        userRole: authContext.groups,
        environment: process.env.REACT_APP_NODE_ENV,
      },
    });
    const newState = {
      companyDetailsLoaded: true,
      companyId: company.companyId,
      companyName: company.name,
      safeCompanyName: company.safeName,
      companyPostingsId: company.companyPostingsId,
      companyProfilesId: company.companyProfilesId,
      subDomain: company.safeName,
      product_modules: company.product_modules,
      company_modifiers: company.company_modifiers,
      ...companyOverrideInfo,
    };
    if (isAdmin() && state.companyId) {
      await loadAdminSpecificSettings();
    }

    setState(state => ({
      ...state,
      ...newState,
    }));
  };

  type IntegrationsResult = PromiseSettledResult<Integration[]>;
  type IntegrationSchedulesResult = PromiseSettledResult<IntegrationSchedule[]>;

  const loadAdminSpecificSettings = async (): Promise<void> => {
    const [integrationsResult, schedulesResult] = await Promise.allSettled([
      IntegrationService.getIntegrations(state.companyId),
      IntegrationService.getIntegrationSchedules(state.companyId),
    ]) as [IntegrationsResult, IntegrationSchedulesResult];

    let integrations: Integration[] = [];
    let schedules: IntegrationSchedule[] = [];

    if (integrationsResult.status === 'fulfilled') {
      integrations = integrationsResult.value;
    } else {
      console.error('Failed to fetch integrations:', integrationsResult.reason);
    }

    if (schedulesResult.status === 'fulfilled') {
      schedules = schedulesResult.value;
    } else {
      console.error('Failed to fetch schedules:', schedulesResult.reason);
    }

    const vcc = await Promise.allSettled(
      integrations.map(
        async integration =>
          await IntegrationService.getCompanyIntegrationConfig(
            state.companyId,
            integration.vendor.id,
            integration.vendorCompanyId
          )
      )
    );
    // TODO: Will this be switched to use the new get all integration details endpoint?
    const vendorCompaniesWithConfiguration = (
      vcc.filter(vcc => vcc.status === 'fulfilled') as PromiseFulfilledResult<VendorCompany>[]
    ).map(vcc => vcc.value);

    // TODO: Let's embrace we're only using Workday :) When we're using more than one integration, this will need to change.
    const workdayVendorCompanyIntegration = vendorCompaniesWithConfiguration[0];

    const vendorIds = vendorCompaniesWithConfiguration?.map(vcc => vcc.vendorId);
    const credentialsResponse = await Promise.all(vendorIds.map(async vendorId => 
      [vendorId, await IntegrationService.getIntegrationCredentials(state.companyId, vendorId)]
    ));
    const credentials = Object.fromEntries(credentialsResponse);

    const newState = { ...state };

    newState.vendorCompaniesWithConfiguration = vendorCompaniesWithConfiguration.map(vcwc => ({
      ...vcwc,
      // We are using Array.filter to get around the vendor possibly being 'undefined' if we use Array.find. We can do this another way if we want?
      vendor: integrations.map(i => ({ ...i.vendor })).filter(i => i.id === vcwc.vendorId)[0],
    }));

    newState.workdayVendorCompany = workdayVendorCompanyIntegration
      ? {
        ...workdayVendorCompanyIntegration,
        // We are using Array.filter to get around the vendor possibly being 'undefined' if we use Array.find. We can do this another way if we want?
        vendor: integrations.filter(i => i.vendorId === workdayVendorCompanyIntegration.vendorId)[0]?.vendor,
      }
      : undefined;
    newState.hasActiveVendorCompanyIntegration = workdayVendorCompanyIntegration !== undefined;
    newState.integrationSchedules = schedules;
    newState.integrationCredentials = credentials;

    setState(state => ({
      ...state,
      ...newState,
    }));
  }

  useEffect(() => {
    refreshContextState();
  }, [state.companyId, authContext.isLoggedIn]);

  useEffect(() => {
    (async () => {
      if (!authContext.companyId) return;

      setState(state => ({
        ...state,
        companyId: authContext.companyId,
      }));
    })();
  }, [authContext.companyId, setState, authContext.isLoggedIn]);

  useEffect(() => {
    const addCategoriesWithParent = (parentValue, destinationHash) => {
      Object.values(state.categories)
        .filter((c: any) => c.parentId === parentValue)
        .forEach((c: any) => {
          destinationHash[c.categoryId] = { ...c, children: {} };
          addCategoriesWithParent(c.categoryId, destinationHash[c.categoryId].children);
        });
    };

    const categoriesTree = {};
    addCategoriesWithParent(null, categoriesTree);
    setState(state => ({ ...state, categoriesTree }));
  }, [state.categories, setState]);

  useEffect(() => {
    if (authContext.isLoggedIn && isAnalyst() && state.companyId) {
      (async () => {
        if (!authContext.isLoggedIn || !state.companyId) return;

        const res = await axios.get(
          `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${state.companyId}/meta/categories`
        );
        // pull each category out of the array and put it in a hash for fast lookup
        const categories = {};
        res.data.forEach(cat => {
          categories[cat.categoryId] = { ...cat, children: [] };
        });
        // to make this helpful, let's make the list bi-directional by adding children
        Object.values(categories).forEach((c: any) => {
          if (categories[c.parentId]) {
            categories[c.parentId].children.push(c.categoryId);
          }
        });
        // update the state and mark the categories as loaded
        setState(state => ({ ...state, categories, categoriesLoaded: true }));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.companyId, authContext.isLoggedIn, setState, state.refreshKey]);

  useEffect(() => {
    if (authContext.isLoggedIn && isAnalyst() && state.companyId) {
      (async () => {
        setState(state => ({ ...state, benchmarksLoading: true }));

        const { benchmarks } = await BenchmarksService.getBenchmarks(state.companyId, 150);
        const defaultBenchmark = benchmarks.find(b => b.companyDefault) || benchmarks[0];

        // update the state and mark the categories as loaded
        setState(state => ({
          ...state,
          defaultBenchmark,
          benchmarks,
          benchmarksLoaded: true,
          benchmarksLoading: false,
        }));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.companyId, setState, state.refreshKey, authContext.isLoggedIn]);

  return <CompanyContext.Provider value={[state, setState, loadAdminSpecificSettings]}>{props.children}</CompanyContext.Provider>;
};

export { CompanyContext, CompanyContextProvider, companyOverrideInfoStorageKey };
