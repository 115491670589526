import {
  SearchOptions,
  SKILL_PROFILES_DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
} from 'contexts/SearchContext';

class SkillProfileSearchParams extends URLSearchParams {
  constructor(skillProfileSearchOptions?: SearchOptions) {
    super();
    this.set('spf', skillProfileSearchOptions?.filterOptions?.searchTermFilter || '');
    this.set('af', JSON.stringify(skillProfileSearchOptions?.filterOptions?.appliedFilters || '{}'));
    this.set(
      'p',
      skillProfileSearchOptions?.pagingSortingOptions?.currentPage?.toString() || DEFAULT_CURRENT_PAGE.toString()
    );
    this.set(
      'n',
      skillProfileSearchOptions?.pagingSortingOptions?.rowsPerPage?.toString() || DEFAULT_ROWS_PER_PAGE.toString()
    );
    this.set(
      'sc',
      skillProfileSearchOptions?.pagingSortingOptions?.sortOptions?.field?.toString() ||
        SKILL_PROFILES_DEFAULT_SORT_COLUMN
    );
    this.set(
      'sd',
      skillProfileSearchOptions?.pagingSortingOptions?.sortOptions?.sort?.toString() || DEFAULT_SORT_DIRECTION
    );
  }

  get searchParamsString(): string {
    return `?${this.toString()}`;
  }
}

export default SkillProfileSearchParams;
