import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

type ProjectedSkillGrowthPopoverProps = {
  projectedSkillGrowthHeaderAnchor: HTMLElement | null;
  handleProjectedSkillGrowthPopoverClose: () => void;
};

const UnorderedList = styled.ul`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
`;

const ListElement = styled.li`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  list-style-type: none;
`;

const ProjectedSkillGrowthInfoPopover = ({
  projectedSkillGrowthHeaderAnchor,
  handleProjectedSkillGrowthPopoverClose,
}: ProjectedSkillGrowthPopoverProps): ReactElement => {
  const theme = useTheme();

  return (
    <Popover
      anchorEl={projectedSkillGrowthHeaderAnchor}
      open={!!projectedSkillGrowthHeaderAnchor}
      onClose={handleProjectedSkillGrowthPopoverClose}
      disableScrollLock={true}
      sx={{ pointerEvents: 'none' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div style={{ maxWidth: '320px', padding: `${theme.customSpacing.px.xs}px` }}>
        <Typography variant="strong" component="div">
          Projected Growth
        </Typography>
        <Divider sx={{ margin: `${theme.customSpacing.px.base}px` }} />

        <Typography variant="caption" component="div">
          <Typography fontSize={theme.customTypography.desktop.caption.size} variant="strong" component="div">
            Overview:
          </Typography>
          <UnorderedList>
            <ListElement>
              Lightcast skill projections provide a forward-looking view of how specific skills are expected to evolve
              over the next two years.
            </ListElement>
            <ListElement>
              These projections focus on the skill itself and remain independent of job classifications.
            </ListElement>
          </UnorderedList>
        </Typography>

        <Divider sx={{ margin: `${theme.customSpacing.px.base}px` }} />

        <Typography variant="caption" component="div">
          <Typography fontSize={theme.customTypography.desktop.caption.size} variant="strong" component="div">
            Methodology:
          </Typography>
          <UnorderedList>
            <ListElement>Projections are based on historical trends spanning the past five years.</ListElement>
            <ListElement>Machine learning algorithms drive the forecasting process.</ListElement>
          </UnorderedList>
        </Typography>

        <Divider sx={{ margin: `${theme.customSpacing.px.base}px` }} />

        <Typography variant="caption" component="div">
          <Typography fontSize={theme.customTypography.desktop.caption.size} variant="strong" component="div">
            Visual Indicators:
          </Typography>
          <UnorderedList>
            <ListElement>
              <Typography fontSize={theme.customTypography.desktop.caption.size} variant="strong" component="div">
                Color and Direction:
              </Typography>
              <UnorderedList>
                <ListElement>
                  The highlighted color and arrow direction offer insights into skill growth relative to the market.
                </ListElement>
              </UnorderedList>
            </ListElement>
            <ListElement>
              <Typography fontSize={theme.customTypography.desktop.caption.size} variant="strong" component="div">
                Growth Categories:
              </Typography>
              <UnorderedList>
                <ListElement>
                  Categories are assigned by comparing the projected 2-year change in national demand for individual
                  skills with the overall demand for all skills.
                </ListElement>
              </UnorderedList>
            </ListElement>
            <ListElement>Definitions and ranges may adjust over time as distributions shift.</ListElement>
          </UnorderedList>
        </Typography>

        <Divider sx={{ margin: `${theme.customSpacing.px.base}px` }} />

        <Typography variant="caption" component="div">
          <Typography fontSize={theme.customTypography.desktop.caption.size} variant="strong" component="div">
            Growth Categories:
          </Typography>
          <UnorderedList>
            <ListElement>
              Rapidly Growing skills are those increasing in demand significantly faster than the market as a whole.
              These skills are highlighted in green with an upward arrow.
            </ListElement>
            <ListElement>
              Growing skills are outpacing the market. These skills are highlighted in green with an upward arrow.
            </ListElement>
            <ListElement>
              Stable growth represents skills growing in line with the overall market. These skills are highlighted in
              gray with a horizontal arrow.
            </ListElement>
            <ListElement>
              Lagging growth may be positive or negative, but is below the market as a whole. These skills are
              highlighted in red with a downward arrow.
            </ListElement>
          </UnorderedList>
        </Typography>
      </div>
    </Popover>
  );
};

export default ProjectedSkillGrowthInfoPopover;
