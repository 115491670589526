/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'helpers/api_helper';
import Role from 'classes/role';
import ReactGA from 'react-ga';
import { bucketizeTime } from 'helpers/analyticsHelper';
import { CopyRoleResponse } from 'types/types';
import { Skill } from 'classes/skillProfile';

type GetRolesParameters = {
  companyId: string;
  fields?: any;
  filters?: any;
  limit?: number;
  offset?: number;
  sortColumn?: string;
  sortDirection?: string;
  searchTerms?: any;
  excludes?: string[];
};

export type GetRolesResponse = {
  roles: Role[];
  totalCount: number;
  moreRolesAvailable: boolean;
};

type TopSkill = Skill & {
  count: number;
};

export type RoleStats = {
  numberReviewed: number;
  numberWithSkillProfiles: number;
  topSkills: TopSkill[];
  totalRoles: number;
};

type GetRoleUploadStatsParams = {
  companyId: string;
  jobCodes: string[];
};

export type GetRoleUploadStatsResponse = {
  totalCurrentRoles: number;
  newRoles: number;
  existingRoles: number;
};

const getRole = async (companyId: string, roleId: string): Promise<Role> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const role = new Role(resp.data);
  return role;
};

const getRoles = async (params: GetRolesParameters): Promise<GetRolesResponse> => {
  const startTime = performance.now();

  params.filters &&
    Object.keys(params.filters).forEach(key => {
      if (params.filters[key].length === 0) {
        delete params.filters[key];
      }
    });

  const url = `${process.env.REACT_APP_API_ROOT}/companies/${params.companyId}/roles/advanced`;
  const resp = await axios.post<GetRolesResponse>(url, params);

  const roles = resp.data?.roles?.map(r => new Role(r)) || [];
  const totalCount = resp.data.totalCount || 0;

  const endTime = performance.now();

  // track num roles
  ReactGA.event({
    category: 'roles',
    action: 'loading',
    label: `num roles: ${roles.length}`,
  });

  ReactGA.event({
    category: 'roles',
    action: 'loading',
    label: `page size: ${params.limit}`,
  });

  // track load time
  ReactGA.event({
    category: 'roles',
    action: 'loading',
    label: `load time (${params.limit}): ${bucketizeTime(endTime - startTime)}`,
  });

  // track if any roles were left
  const moreRolesAvailable = totalCount > roles.length;

  return { roles, totalCount, moreRolesAvailable };
};

const getRoleFacets = async (companyId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/role/facets`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const facets = resp.data.facets;

  const baselineFacets = {
    jobFamily: [],
    jobFunction: [],
    emsiTitle: [],
    occupation: [],
    specializedOccupation: [],
    status: [],
    reviewed: [],
    tags: [],
    skills: [],
  };

  return { ...baselineFacets, ...facets };
};

const getRoleStats = async (companyId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/role/stats`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const stats = resp.data;

  const baselineStats = {
    totalRoles: 0,
    numberReviewed: 0,
    numberWithSkillProfiles: 0,
  };

  return { ...baselineStats, ...stats };
};

const createRole = async ({
  companyId,
  jobCode,
  roleName,
  jobTitle,
  jobFamily,
  jobFunction,
  details,
  tags,
  aliases,
  temporary,
}): Promise<any> => {
  const resp = await axios.post(`${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles`, {
    jobCode,
    roleName,
    jobTitle,
    jobFamily,
    jobFunction,
    details,
    tags,
    aliases,
    temporary,
  });

  return resp.data;
};

const updateRole = async (companyId: string, roleId: string, updateData: any) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}`;
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios.patch(url, { updateData }, headers);

  return resp.data.success;
};

const deleteRole = async (role: { customerId: string; roleId: string }) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${role.customerId}/roles/${role.roleId}`;
  const res = await axios.delete(url);

  return res.status === 200;
};

const setRoleSkills = async (companyId: string, roleId: string, skills: any) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/skills`;
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios.put(url, { skills }, headers);

  return resp.data.success;
};

const addRoleSkills = async (companyId: string, roleId: string, skills: any) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/skills`;
  const body = { skills };
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios.post(url, body, headers);

  return resp.data.success;
};

const deleteRoleSkills = async (companyId: string, roleId: string, skillIds: string[]): Promise<boolean> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/skills`;
  const body = { skillIds };

  const resp = await axios.delete(url, { data: body });

  return resp.status === 200;
};

const patchRoleSkill = async (companyId: string, roleId: string, skill: Skill) => {
  const skillId = skill.skillId;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/skills/${skillId}`;
  const resp = await axios.patch(url, { skill });

  return resp.status === 200;
};

const addTag = async (companyId: string, roleId: string, tagText: any) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/attributes/tag`;
  const res = await axios.post(url, { tagText });

  return res.status === 200;
};

const removeTag = async (companyId: string, roleId: string, tagId: string) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/attributes/tag/${tagId}`;
  const res = await axios.delete(url);

  return res.status === 200;
};

const addAlias = async (companyId: string, roleId: string, aliasText: any) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/attributes/alias`;
  const res = await axios.post(url, { aliasText });

  return res.status === 200;
};

const removeAlias = async (companyId: string, roleId: string, aliasId: string) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/attributes/alias/${aliasId}`;
  const res = await axios.delete(url);

  return res.status === 200;
};

const addBenchmarkSkills = async (companyId: string, roleId: string): Promise<boolean> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/add_benchmark_skills`;
  const res = await axios.post(url);

  return res.status === 200;
};

const copyRole = async (companyId: string, roleId: string, roleUpdates: any): Promise<CopyRoleResponse> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/${roleId}/copy`;
  const body = { roleUpdates };
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios.post(url, { data: body }, headers);

  return resp.data;
};

const getRoleUploadStats = async ({
  companyId,
  jobCodes,
}: GetRoleUploadStatsParams): Promise<GetRoleUploadStatsResponse> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/upload/stats`;
  const body = { jobCodes };
  const resp = await axios.post<GetRoleUploadStatsResponse>(url, body);
  const rolesCount = resp.data;

  // track number of new and existing roles
  ReactGA.event({
    category: 'roles',
    action: 'Role upload stats',
    label: `New Roles: ${rolesCount.newRoles} / Existing Roles: ${rolesCount.existingRoles}`,
  });

  return rolesCount;
};

export default {
  getRole,
  getRoles,
  getRoleFacets,
  getRoleStats,
  updateRole,
  createRole,
  deleteRole,
  setRoleSkills,
  addRoleSkills,
  deleteRoleSkills,
  patchRoleSkill,
  addTag,
  removeTag,
  addAlias,
  removeAlias,
  addBenchmarkSkills,
  copyRole,
  getRoleUploadStats,
};
