import axios from 'helpers/api_helper';

export const triggerExportEventLogs = async (companyId: string) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/eventLogs/export`;
  const resp = await axios.get<{ success: boolean }>(url);
  return resp.data.success;
};

export const triggerExportFile = async (
  companyId: string,
  exportType: 'roles' | 'skillProfiles' | 'tagsAliases',
  ids?: string[]
) => {
  const url = `${process.env.REACT_APP_API_COMPANY_DATASTORE_ROOT}/file/${exportType}/export`;
  const config = {
    params: {
      ids: ids ? ids.join(',') : undefined,
      companyId,
      exportType,
    },
    headers: {
      'Content-Type': 'application/json',
      'x-ecds-customerId': companyId,
    },
    scope: 'company_datastore_roles',
  };
  const res = await axios.post<{ success: boolean }>(url, null, config);
  return res.data.success;
};
